import FIREBASE_CONFIG_DEV from '../../firebase-public-config.dev.json'
import FIREBASE_CONFIG_PROD from '../../firebase-public-config.prod.json'

const CONFIG_MAP = {
  'rundown-app-641e0': FIREBASE_CONFIG_DEV,
  'rundown-studio-46e96': FIREBASE_CONFIG_PROD,
}

export function getFirebaseConfig () {
  return CONFIG_MAP[import.meta.env.VITE_PROJECT_ID]
}
