import { addDays, isSameDay } from '@rundown-studio/timeutils'

/**
 * Returns a string representing the relative day in the specified timezone.
 *
 * @param {Date} date - The date to be compared with the current date.
 * @param {string} timezone - The timezone in which to evaluate the date.
 * @returns {string} - Returns "today" if the date is the same day as the current date in the given timezone,
 * "tomorrow" if it's the next day, "yesterday" if it's the previous day, or the name of the day of the week
 * (e.g., "Monday") otherwise.
 */
export default function getRelativeDayString (date, timezone = 'UTC') {
  const options = { timeZone: timezone, weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }

  const now = new Date()
  const targetDateFormatted = new Intl.DateTimeFormat('en-US', options).format(date)

  const targetDate = new Date(targetDateFormatted)

  if (isSameDay(now, date, { timezone })) {
    return 'today'
  } else if (isSameDay(now, addDays(date, -1, { timezone }), { timezone })) {
    return 'tomorrow'
  } else if (isSameDay(now, addDays(date, 1, { timezone }), { timezone })) {
    return 'yesterday'
  } else {
    return targetDate.toLocaleDateString('en-US', { weekday: 'long', timeZone: timezone })
  }
}
