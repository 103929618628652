import { atom } from 'jotai'

//
// Event
//
export const eventAtom = atom(null)
export const clearEventAtom = atom(
  null,
  (get, set) => {
    set(eventAtom, null)
  },
)

const _eventInitAtom = atom(false)
export const eventInitAtom = atom(
  (get) => get(_eventInitAtom),
  (get, set, bool = true) => {
    set(_eventInitAtom, bool)
  },
)
