import RundownOutputHeroCue from './RundownOutputHeroCue.jsx'
import RundownOutputCueList from './RundownOutputCueList.jsx'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import * as OUTPUT_TYPES from '../../../constants/outputConfigTypes.js'
import RundownOutputPrompter from './RundownOutputPrompter.jsx'

export default function RundownOutputBody ({ rundown, timestamps, moment, runner, cues, cells, visibleColumn, controlsVisible }) {
  const [searchParams] = useSearchParams()

  const layout = searchParams.get(OUTPUT_TYPES.LAYOUT) || OUTPUT_TYPES.LIST

  if (layout === OUTPUT_TYPES.SINGLE) return <RundownOutputHeroCue rundown={rundown} cues={cues} timestamps={timestamps} moment={moment} runner={runner} />

  if (layout === OUTPUT_TYPES.PROMPTER) return <RundownOutputPrompter rundown={rundown} cues={cues} runner={runner} cells={cells} visibleColumn={visibleColumn} controlsVisible={controlsVisible} />

  return <RundownOutputCueList cues={cues} timestamps={timestamps} moment={moment} cells={cells} visibleColumn={visibleColumn} />
}

RundownOutputBody.propTypes = {
  rundown: PropTypes.object,
  timestamps: PropTypes.object,
  moment: PropTypes.object,
  runner: PropTypes.object,
  columnId: PropTypes.string,
  columns: PropTypes.array,
  cues: PropTypes.object,
  cells: PropTypes.object,
  visibleColumn: PropTypes.object,
  controlsVisible: PropTypes.bool,
}
