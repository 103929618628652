import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import CueColorPicker from './rundown/CueColorPicker'

function Portal({ children }) {
  return createPortal(children, document.getElementById('context-menu-root'))
}

export default function ContextMenu ({ openEvent, onClose, items = [], onClick, children, ...props }) {
  const contextMenuRef = useRef()
  const [position, setPosition] = useState({ x: 0, y: 0, scale: 0.95, opacity: 0 })

  const memoizedOnOutsideClick = useCallback(onOutsideClick, [contextMenuRef])
  function onOutsideClick(event) {
    if (contextMenuRef?.current?.contains(event.target)) return
    onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', memoizedOnOutsideClick)
    return () => document.removeEventListener('mousedown', memoizedOnOutsideClick)
  }, [memoizedOnOutsideClick])

  function showContextMenu(event) {
    event.preventDefault()

    const mouseY = event.clientY
    const lower = mouseY > (window.innerHeight / 2)
    const contextMenuHeight = contextMenuRef?.current?.clientHeight || 0
    const y = lower ? mouseY - contextMenuHeight : mouseY

    const mouseX = event.clientX
    const left = mouseX < (window.innerWidth / 2)
    const contextMenuWidth = contextMenuRef?.current?.clientWidth || 0
    const x = left ? mouseX : mouseX - contextMenuWidth

    setPosition({ x, y, scale: 1, opacity: 1 })
  }

  useEffect(() => {
    if (openEvent) showContextMenu(openEvent)
  }, [openEvent])

  function stopPropagation (event) {
    event.stopPropagation()
  }

  return (
    <Portal>
      <div
        ref={contextMenuRef}
        id="context-menu"
        style={{
          top: position.y,
          left: position.x,
          transform: `scale(${position.scale})`,
          opacity: position.opacity,
        }}
        onClick={onClose}
        className={['fixed bg-black border border-white/20 z-[1000] rounded transition-[transform,opacity] duration-50'].join(' ')}
        {...props}
      >
        <ul className="w-full whitespace-nowrap px-1 py-1 text-sm flex flex-col flex-wrap space-y-1">
          {items?.map((item, index) => {
            if (item.hidden) return null
            if (item.type === 'spacer') {
              return (
                <div key={`spacer_${index}`} className="py-1">
                  <div className="bg-gray-800 h-px "></div>
                </div>
              )
            }
            if (item.type === 'colour') {
              return (
                <li key={`colour_${index}`}>
                  <CueColorPicker onClick={onClick.bind(null, item?.value)} rundownColors={item.options} />
                </li>
              )
            }
            if (item.type === 'secondary') {
              return (
                <li key={item?.value}>
                  <div
                    className={[
                      'relative px-2 py-1 w-full flex text-left rounded',
                      item.disabled ? 'text-gray-500 pointer-events-none' : 'group/menuitem hover:bg-gray-800 focus:outline-none focus:ring cursor-pointer',
                    ].join(' ')}
                    onClick={stopPropagation /* Needed for touch devices */}
                    disabled={item?.disabled}
                  >
                    <div className="flex w-full space-x-4 items-center justify-between">
                      <span>{item?.name}</span>
                      {' '}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <div
                      className={
                        ['absolute p-2 -top-1 opacity-0 hidden group-hover/menuitem:opacity-100 group-hover/menuitem:block bg-black border border-white/20 rounded',
                          position.x + contextMenuRef?.current?.clientWidth < window.innerWidth - contextMenuRef?.current?.clientWidth ? 'left-full' : 'right-full',
                        ]
                          .join(' ')
                      }
                    >
                      <ul>
                        {item.options?.map((option) => {
                          return (
                            <li key={option.value}>
                              <button
                                className="px-2 py-1 w-full text-left hover:bg-gray-800 focus:outline-none focus:ring rounded"
                                onClick={onClick.bind(null, item?.value, option.value)}
                              >
                                {option?.name}
                              </button>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </li>
              )
            }
            return (
              <li key={item?.value}>
                <button
                  style={{
                    backgroundColor: item.colour,
                  }}
                  className={[
                    'px-2 py-1 w-full flex justify-between hover:brightness-125 focus:outline-none focus:ring rounded disabled:pointer-events-none disabled:opacity-50',
                    item.colour ? 'brightness-105' : 'hover:bg-gray-800',
                  ].join(' ')}
                  onClick={onClick.bind(null, item?.value)}
                  disabled={item?.disabled}
                >
                  <span>{item?.name}</span>
                  {item?.icon && (
                    <span className="pl-2">
                      <FontAwesomeIcon icon={item?.icon} />
                    </span>
                  )}
                </button>
              </li>
            )
          })}
        </ul>
        {children}
      </div>
    </Portal>
  )
}

ContextMenu.propTypes = {
  openEvent: PropTypes.instanceOf(MouseEvent).isRequired, // On button click event
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element,
}
