import ModalWrapper from './ModalWrapper.jsx'
import { QRCodeCanvas } from 'qrcode.react'
import PropTypes from 'prop-types'
import { useRef } from 'react'

export default function QrCodeModal ({ open, setOpen, url = null }) {
  const qrCanvasWrapperRef = useRef()

  function onDownloadQr() {
    const canvas = qrCanvasWrapperRef.current.children[0]
    const link = document.createElement('a')
    link.download = 'rundown-qr-code.png'
    link.href = canvas.toDataURL('image/png')
    link.click()
  }

  return (
    <ModalWrapper
      title="QR Code"
      open={[open, setOpen]}
      buttons={[{
        text: 'Close',
        colour: 'dark',
        onClick: () => setOpen(false),
      }]}
    >
      <div className="px-6 text-center" ref={qrCanvasWrapperRef}>
        <QRCodeCanvas
          className="rounded mx-auto w-48"
          value={url}
          size="600"
          marginSize="4"
        />
        <button
          className="text-xs font-semibold text-gray-500 hover:text-gray-300"
          onClick={onDownloadQr}
        >
          Download QR Code
        </button>
      </div>
    </ModalWrapper>
  )
}

QrCodeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  url: PropTypes.string,
}
