import * as React from 'react'
import PropTypes from 'prop-types'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react'
import Button from '../Button'

export function usePopover({
  initialOpen = false,
  placement = 'bottom',
  modal,
  open: controlledOpen,
  onOpen = () => {},
  onClose = () => {},
  onOutsideClick = () => {},
  onEnterKey = () => {},
  onOpenChange: setControlledOpen,
  closeWithEnter = false,
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState()
  const [descriptionId, setDescriptionId] = React.useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const setOpenWithMiddleware = (open, event, reason) => {
    event.preventDefault()
    if (open) {
      onOpen()
    } else {
      if (reason === 'outside-press') onOutsideClick()
      if (reason === 'enter-key') onEnterKey()
      onClose()
    }
    setOpen(open)
  }

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpenWithMiddleware,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const customEnterInteraction = {
    floating: {
      onKeyDown: (event) => {
        if (closeWithEnter && event.key === 'Enter') {
          setOpenWithMiddleware(false, event, 'enter-key')
        }
      },
    },
  }

  const interactions = useInteractions([click, dismiss, role, customEnterInteraction])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId],
  )
}

const PopoverContext = React.createContext(null)

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext)

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export function Popover({
  children,
  modal = false,
  ...restOptions
}) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  modal: PropTypes.bool,
}

export const PopoverTrigger = React.forwardRef(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    )
  }

  return (
    <button
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

PopoverTrigger.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  asChild: PropTypes.bool,
}

export const PopoverContent = React.forwardRef(function PopoverContent({ children, style, className, onSave, hideButtons = false, saveDisabled = false, onCancel, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const [loading, setLoading] = React.useState(false)

  if (!floatingContext.open) return null

  const handle = async (func) => {
    if (!func) return context.setOpen(false)
    setLoading(true)
    try {
      const result = func()
      if (result instanceof Promise) {
        await result
      }
      context.setOpen(false)
    } catch (error) {
      console.error('Error during handling:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          ref={ref}
          style={{ ...context.floatingStyles, ...style }}
          className={['z-[100] rounded-lg border border-white/10 bg-black/80 p-2.5 shadow-lg backdrop-blur-lg', className].join(' ')}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          {children}
          {!hideButtons && (
            <div className="flex justify-end space-x-2.5 mt-2.5">
              <Button className="grow px-2" size="sm" text="Cancel" colour="dark" loading={loading} onClick={() => handle(onCancel)} />
              <Button className="grow px-2" size="sm" text="Save" colour="dark" loading={loading} disabled={saveDisabled} onClick={() => handle(onSave)} />
            </div>
          )}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})

PopoverContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  style: PropTypes.object,
  className: PropTypes.string,
  onSave: PropTypes.func,
  saveDisabled: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onCancel: PropTypes.func,
}
