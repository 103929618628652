import { atom } from 'jotai'

export const PRESHOW = 'PRESHOW'
export const ONAIR = 'ONAIR'
export const ENDED = 'ENDED'

//
// Runner
//
const _runnerAtom = atom(null)
export const runnerAtom = atom(
  (get) => {
    const runner = get(_runnerAtom)

    // If new runner or null, return actual runner
    if (!runner || runner.originalCues) return runner

    return null
  },
  (get, set, runner) => {
    set(_runnerAtom, runner)
  },
)

export const runnerStateAtom = atom(
  (get) => {
    const runner = get(runnerAtom)
    if (!runner) return PRESHOW
    if (!runner.timesnap.cueId) return ENDED
    return ONAIR
  },
  null,
)

//
// Ping
//
export const pingAtom = atom(0)
