import { faExclamationCircle, faUpload, faCircleNotch, faSearch, faCircleXmark, faArrowDownShortWide, faCheck, faPlusCircle, faEllipsis, faFolderPlus, faArchive, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef, useMemo } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import DashboardWrapper from '../../components/dashboard/DashboardWrapper'
import { importFile } from '../../firestore.js'
import Button from '../../components/Button'
import ModalWrapper from '../../components/modal/ModalWrapper'
import UpgradeModal from '../../components/modal/UpgradeModal'
import { IMPORT } from '../../constants/billingTypes'
import { ALPHABETICAL, CREATED_NEW_OLD, CREATED_OLD_NEW, RUNDOWN_START_TIME } from '../../constants/sortTypes.js'
import RundownCard from '../../components/dashboard/partials/RundownCard.jsx'
import EventCard from '../../components/dashboard/partials/EventCard.jsx'
import Fuse from 'fuse.js'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../components/interactives/Tooltip.jsx'
import { Menu, MenuItem } from '../../components/interactives/DropdownMenu.jsx'

export default function DashboardMain () {
  const {
    user,
    team,
    plan,
    rundownList,
    eventList,
  } = useOutletContext()
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sortedBy, setSortedBy] = useState(RUNDOWN_START_TIME)
  const [autoAddHeadings, setAutoAddHeadings] = useState(false)
  const [autoAddBgColors, setAutoAddBgColors] = useState(false)
  const fileInputRef = useRef()

  const navigate = useNavigate()

  const sortItems = (items, sortBy) => {
    return items.slice().sort((a, b) => {
      switch (sortBy) {
        case RUNDOWN_START_TIME:
          return new Date(a.startTime) < new Date(b.startTime) ? 1 : -1
        case CREATED_NEW_OLD:
          return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        case CREATED_OLD_NEW:
          return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        default: {
          const _A = a.name.toUpperCase()
          const _B = b.name.toUpperCase()
          return (_A < _B) ? -1 : (_A > _B) ? 1 : 0
        }
      }
    })
  }

  const sortedEvents = useMemo(() => sortItems(eventList.filter((e) => e.archivedAt == null), sortedBy), [eventList, sortedBy])
  const sortedRundowns = useMemo(() => sortItems(rundownList.filter((r) => r.archivedAt == null), sortedBy), [rundownList, sortedBy])

  const filterItems = (items, searchText) => {
    const fuse = new Fuse(items, { keys: ['name'] })
    return searchText ? fuse.search(searchText).map((result) => result.item) : items
  }

  const filteredEvents = useMemo(() => filterItems(sortedEvents, searchText), [searchText, sortedEvents])
  const filteredRundowns = useMemo(() => filterItems(sortedRundowns, searchText).filter((rundown) => !rundown.eventId), [searchText, sortedRundowns])

  async function handleFileImport (event) {
    const file = event.target.files[0]
    const timezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone // TODO: let user choose timezone?
    setUploading(0)
    await importFile(team.id, file, { timezone, autoAddHeadings, autoAddBgColors }, (progress) => setUploading(progress))
    setUploading(100)
    setTimeout(() => setUploading(true), 1000)
  }

  function withinRundownsLimit () {
    const current = rundownList.length
    const limit = plan?.limits?.rundowns

    if (limit === -1) return true
    if (current < limit) return true
    return false
  }

  async function handleOnCreateRundown () {
    if (withinRundownsLimit()) return navigate('/create')
    setShowUpgradeModal(true)
  }

  // if (!team) return <DashboardCheck />

  return (
    <DashboardWrapper
      data-label="layout-dashboard"
      title={(
        <div className="flex flex-wrap gap-4">
          <p className="grow">Dashboard</p>
          <div className="flex flex-wrap gap-x-6 gap-y-4">
            <div className="flex">
              <Button
                className="text-base rounded-r-none"
                text="New rundown"
                icon={faPlusCircle}
                colour="dark"
                onClick={handleOnCreateRundown}
                data-label="create-rundown-button"
              />
              <Menu className="rounded-l-none" icon={faChevronDown}>
                <MenuItem
                  icon={faFolderPlus}
                  label="New event folder"
                  onClick={() => navigate('/create-event')}
                />
                <MenuItem
                  icon={faUpload}
                  label="Import CSV"
                  onClick={() => {
                    if (!plan.features.includes(IMPORT)) return setShowUpgradeModal(true)
                    setImportModalOpen(true)
                  }}
                />
              </Menu>
            </div>
          </div>
        </div>
      )}
    >
      <>
        {!user?.emailVerified
          ? (
              <div className="px-4 py-2 mb-4 flex flex-col gap-4 sm:flex-row justify-between items-center bg-white/10 rounded">
                <div className="flex gap-2 items-center">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <span>Please verify your email address before continuing.</span>
                </div>
                <Button text="Verify my email" onClick={() => navigate('/verify')} />
              </div>
            )
          : ''}

        {/* IMPORT CSV MODAL */}
        <ModalWrapper
          open={[importModalOpen, setImportModalOpen]}
          title="Import CSV"
          onHide={() => setUploading(false)}
          buttons={[
            { text: 'Close', onClick: () => setImportModalOpen(false) },
          ]}
        >
          <p className="mt-2 text-sm text-gray-400">
            Let Rundown Studio work its magic alongside OpenAI to seamlessly import your run of show into a workable Rundown Studio project.
            <strong>Note:</strong>
            {' '}
            This feature is experimental.
          </p>
          <div className="mt-4 flex items-center justify-center">
            { uploading === false
              ? (
                  <div className="flex flex-col">
                    <div className="mb-2 flex flex-col sm:flex-row items-center gap-2">
                      <Tooltip>
                        <TooltipTrigger>
                          <label
                            htmlFor="addTitles"
                            className="flex px-2 py-1 gap-2 w-[200px] border border-gray-700 rounded hover:border-gray-600 transition-colors cursor-pointer"
                          >
                            <input id="addTitles" type="checkbox" checked={autoAddHeadings} onChange={(e) => setAutoAddHeadings(e.target.checked)} />
                            <span className="text-gray-300 text-sm">Auto-add headings</span>
                          </label>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>Inserts helpful headings to add logical structure to the rundown.</span>
                        </TooltipContent>
                      </Tooltip>

                      <Tooltip>
                        <TooltipTrigger>
                          <label
                            htmlFor="addColors"
                            className="flex px-2 py-1 gap-2 w-[200px] border border-gray-700 rounded hover:border-gray-600 transition-colors cursor-pointer"
                          >
                            <input id="addColors" type="checkbox" checked={autoAddBgColors} onChange={(e) => setAutoAddBgColors(e.target.checked)} />
                            <span className="text-gray-300 text-sm">Auto-add colors</span>
                          </label>
                        </TooltipTrigger>
                        <TooltipContent>
                          <span>Highlights the most important cues with background colors.</span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <button className="bg-gray-800 hover:bg-gray-700 transition-colors px-3 py-2 rounded" onClick={() => fileInputRef.current?.click()}>
                      <FontAwesomeIcon icon={faUpload} size="xs" />
                      {' '}
                      Upload CSV file
                    </button>
                  </div>
                )
              : uploading === true
                ? (
                    <div
                      className="flex items-center gap-2 h-9 rounded-md border border-white/20 px-1"
                      title="The file is processing on our server, expect an email in 3 min when completed"
                    >
                      <FontAwesomeIcon icon={faCheck} fixedWidth />
                      <strong>Upload complete</strong>
                      <small>We&apos;ll send you an email when the rundown is ready.</small>

                    </div>
                  )
                : (
                    <div className="flex items-center gap-2 h-9 rounded border border-white/20 px-3">
                      <span>Uploading:</span>
                      <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" fixedWidth />
                      <strong className="tabular-nums">
                        {uploading}
                        %
                      </strong>
                    </div>
                  )}
          </div>
          <input
            ref={fileInputRef}
            className="hidden"
            type="file"
            accept=".csv"
            onChange={handleFileImport}
          />
        </ModalWrapper>

        <div className="mt-4 flex flex-col sm:flex-row gap-2 justify-between">
          <span className="flex-1"></span>
          <div className="pr-3 bg-gray-100/5 focus-within:bg-gray-100/10 focus-within:ring rounded w-full sm:w-min whitespace-nowrap flex items-center justify-between">
            <input
              placeholder="Find event or rundown"
              className="px-3 h-8 bg-transparent focus:outline-none"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
            {searchText
              ? <button className="h-7 focus:outline-none focus:ring rounded" onClick={() => setSearchText('')}><FontAwesomeIcon icon={faCircleXmark} /></button>
              : <FontAwesomeIcon icon={faSearch} />}
          </div>

          <Menu label="Sort" icon={faArrowDownShortWide}>
            <MenuItem
              label="Rundown start time"
              onClick={() => setSortedBy(RUNDOWN_START_TIME)}
              active={sortedBy === RUNDOWN_START_TIME}
            />
            <MenuItem
              label="Created (latest)"
              onClick={() => setSortedBy(CREATED_NEW_OLD)}
              active={sortedBy === CREATED_NEW_OLD}
            />
            <MenuItem
              label="Created (oldest)"
              onClick={() => setSortedBy(CREATED_OLD_NEW)}
              active={sortedBy === CREATED_OLD_NEW}
            />
            <MenuItem
              label="Alphabetical"
              onClick={() => setSortedBy(ALPHABETICAL)}
              active={sortedBy === ALPHABETICAL}
            />
          </Menu>

          <Menu icon={faEllipsis}>
            <MenuItem
              icon={faArchive}
              label="Show archive"
              onClick={() => navigate('archive')}
            />
          </Menu>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {/* EVENTS */}
          <div className="flex col-span-full items-center gap-2">
            <div className="w-4 bg-gray-800 h-0.5"></div>
            <p className="text-xs text-gray-600">Events</p>
            <div className="grow bg-gray-800 h-0.5"></div>
          </div>

          {filteredEvents.length === 0
            ? (
                <div className="h-[8rem] border border-dashed border-gray-800 text-gray-500 rounded flex flex-col justify-center text-center">
                  No events found
                </div>
              )
            : filteredEvents.map((event) => {
              const eventRundowns = rundownList.filter((rundown) => rundown.eventId === event.id)
              return (
                <EventCard
                  key={event.id}
                  event={event}
                  rundowns={eventRundowns.filter((rundown) => !rundown.archivedAt)}
                  archivedCount={eventRundowns.filter((rundown) => rundown.archivedAt).length}
                />
              )
            })}

          <div className="flex col-span-full items-center gap-2">
            <div className="w-4 bg-gray-800 h-0.5"></div>
            <p className="text-xs text-gray-600">Rundowns</p>
            <div className="grow bg-gray-800 h-0.5"></div>
          </div>

          {/* RUNDOWNS */}
          {filteredRundowns.length === 0
            ? (
                <div className="h-[8rem] border border-dashed border-gray-800 text-gray-500 rounded flex flex-col justify-center text-center">
                  No rundowns found
                </div>
              )
            : filteredRundowns.map((rundown) => (
              <RundownCard
                key={rundown.id}
                rundown={rundown}
                eventList={eventList}
              />
            ))}
        </div>

        <UpgradeModal
          open={showUpgradeModal}
          setOpen={setShowUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message=""
        />

      </>
    </DashboardWrapper>
  )
}
