import { atom } from 'jotai'
import createMoment from '../utils/createMoment.js'
import { fastDeepEqual } from '@rundown-studio/utils'
import { runnerAtom } from './runner.store.js'

/**
 * Private value store
 */
const _moment = atom(null)

/**
 * Public interface to moment
 * Getter: Get moment
 * Setter: Re-creates moment, takes no parameters
 */
export const momentAtom = atom(
  (get) => get(_moment),
  (get, set) => {
    // Collect params
    const runner = get(runnerAtom)
    if (!runner) {
      set(_moment, null)
      return
    }

    // Create moment
    const newMoment = createMoment(runner.timesnap)

    // Preserve existing object signature if there are no changes
    const oldMoment = get(_moment)
    const isEqual = fastDeepEqual(newMoment, oldMoment)
    if (!isEqual) set(_moment, newMoment)
  },
)
