import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { teamsByUid } from './firestore.js'
import { updateUserId } from './sentry.js'
import commitUTMParams from './utils/commitUTMParams.js'
import * as GtagService from './utils/GtagService.js'
import * as PaddleService from './utils/PaddleService.js'
import { getFirebaseConfig } from './utils/getFirebaseConfig.js'

// Initialize Firebase
const firebaseConfig = getFirebaseConfig()
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)
let unsubscribe = null

// Create an awaitable authStateInit
let authStateResolve
const authStateInit = new Promise((r) => authStateResolve = r)

/**
 * Initialize the auth state listener.
 * Makes sure the onAuthStateChanged listener is only attached once per pageload.
 *
 * Note: Initiate Gtag when we have a user, this way we know that they accepted the privacy policy.
 *       Same for Paddle to activate Paddle Retain and Engagement.
 *
 * @idempotent
 * @return {void}
 */
function initializeAuthStateListener() {
  if (unsubscribe) return
  unsubscribe = onAuthStateChanged(auth, (user) => {
    updateUserId(user?.uid)
    if (user) {
      GtagService.init(firebaseConfig.measurementId, firebaseConfig.adsId)
      PaddleService.init()
    }
    if (user?.email) commitUTMParams()
    authStateResolve()
  })
}

/**
 * Get the current user after ensuring auth state is initialized.
 * @return {Promise<FirebaseUser|null>}
 */
export async function getUser() {
  await authStateInit
  return auth.currentUser
}

/**
 * Get a user's teams.
 * Return an empty array if there's no user.
 * @return {Promise<Team[]>}
 */
export async function getUserTeams() {
  const user = await getUser()
  if (!user) return []
  const { data: teams } = await teamsByUid(user.uid)
  return teams
}

// Initialize the auth state listener immediately
initializeAuthStateListener()
