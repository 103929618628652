import { gtag, initDataLayer, install } from 'ga-gtag'

let enabled = false

// measurementId: G-MGGJVY1YRZ
// adsId:         AW-11481361775

export async function init (analyticsId, adsId) {
  initDataLayer()
  gtag('consent', 'default', {
    ad_storage: 'granted',
    analytics_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'denied',
  })
  install(analyticsId)
  gtag('config', adsId)
  enabled = true
}

export function pageview (location) {
  if (!enabled) return
  gtag('event', 'page_view', {
    page_location: location,
  })
}

export function signup () {
  if (!enabled) return
  gtag('event', 'conversion', {
    send_to: 'AW-11481361775/TIxXCIieqdwZEO_a3uIq _a3UIg',
  })
}

export function beginCheckout ({
  value = 60.0,
  currency = 'USD',
}) {
  if (!enabled) return
  gtag('event', 'conversion', {
    send_to: 'AW-11481361775/51J5CI6eqdwZEO_a3uIq',
    value: value,
    currency: currency,
  })
}

export function purchase ({
  value = 60.0,
  currency = 'USD',
  transactionId = '',
}) {
  if (!enabled) return
  gtag('event', 'conversion', {
    send_to: 'AW-11481361775/ z89-CIueqdwZEO_a3uIq',
    value: value,
    currency: currency,
    transaction_id: transactionId,
  })
}
