import DashboardWrapper from './DashboardWrapper'
import { useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { useOutletContext, useParams } from 'react-router-dom'
import { getTransactions } from '../../firestore'
import UpgradeMessage from '../UpgradeMessage'
import Button from '../Button'
import CancelSubscriptionModal from '../modal/CancelSubscriptionModal'

export default function Billing() {
  const {
    plan,
  } = useOutletContext()

  { /* FREE PLAN */ }
  if (!plan || !plan.billingCycle) return (
    <DashboardWrapper title="Billing">
      <PlanFree plan={plan} />
    </DashboardWrapper>
  )

  { /* EVENT PLAN */ }
  if (plan.billingCycle.interval === 'once') return (
    <DashboardWrapper title="Billing">
      <PlanEvent plan={plan} />
    </DashboardWrapper>
  )

  { /* SUBSCRIPTION PLAN */ }
  return (
    <DashboardWrapper title="Billing">
      <PlanSubscription plan={plan} />
    </DashboardWrapper>
  )
}

const PlanFree = ({ plan }) => {
  return (
    <PlanWrapper imageUrl={plan.imageUrl} planName={plan.name}>
      <UpgradeMessage message="Upgrade your account to add more features and team members." />
    </PlanWrapper>
  )
}
PlanFree.propTypes = {
  plan: PropTypes.object,
}

const PlanEvent = ({ plan }) => {
  return (
    <PlanWrapper imageUrl={plan.imageUrl} planName={`${plan.name} • ${plan.billingCycle.daysActive} Day Event`} transactionIds={plan.transactionIds}>
      <p className="text-xs">
        Event ends:
        {format(new Date(plan.billingCycle.endsAt), 'd MMM, yyyy')}
      </p>
      {/* Plan limits */}
      <div className="flex flex-col gap-1 text-xs">
        <p className="">
          Your plan allows for
          {plan?.limits?.members || 1}
          {' '}
          team members.
        </p>
      </div>
    </PlanWrapper>
  )
}
PlanEvent.propTypes = {
  plan: PropTypes.object,
}

const PlanSubscription = ({ plan }) => {
  const { teamId } = useParams()
  const [showCancelModal, setShowCancelModal] = useState(false)
  return (
    <PlanWrapper
      imageUrl={plan.imageUrl}
      planName={`${plan.name} plan`}
      transactionIds={plan.transactionIds}
      actionButton={(
        <Button
          text="Cancel"
          onClick={() => setShowCancelModal(true)}
          colour="dark"
          disabled={plan.scheduledChange?.action === 'cancel'}
          toolTip={plan.scheduledChange?.action === 'cancel' ? 'Your subscription is already scheduled for cancelation' : ''}
          showTooltipWhenDisabled={true}
        />
      )}
    >
      {/* Plan limits */}
      <div className="flex flex-col gap-2 text-xs">
        {/* Billing interval, or cancelled */}
        {plan.scheduledChange?.action === 'cancel'
          ? (
              <>
                <p className="px-1 text-[10px] font-bold text-gray-100 bg-gray-700 w-max rounded-sm uppercase">Cancelled</p>
                <p className="text-xs text-gray-300">
                  Subscription expires:
                  {format(new Date(plan.scheduledChange.effective_at), 'd MMM, yyyy')}
                </p>
              </>
            )
          : (
              <p>
                Billed:
                <span className="capitalize">{plan.billingCycle.interval}</span>
                ly. Next payment date:
                {format(new Date(plan.billingCycle.endsAt), 'd MMM, yyyy')}
                .
              </p>
            )}
        <p className="">
          Your plan allows for
          {plan?.limits?.members || 1}
          {' '}
          team members. Want to add more?
          <a href="mailto:hello@rundownstudio.app" className="underline hover:text-gray-300 cursor-pointer">Email us here</a>
          .
        </p>
      </div>

      {showCancelModal && <CancelSubscriptionModal open={showCancelModal} setOpen={setShowCancelModal} plan={plan} teamId={teamId} />}
    </PlanWrapper>
  )
}
PlanSubscription.propTypes = {
  plan: PropTypes.object,
}

const PlanWrapper = ({ imageUrl, planName, transactionIds = [], children, actionButton = <></> }) => {
  const [showTransactions, setShowTransactions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transactions, setTransactions] = useState()

  const handleGetTransactions = async () => {
    if (showTransactions) return setShowTransactions(false)
    setLoading(true)
    const { data } = await getTransactions(transactionIds.join(','))

    setTransactions(data)
    setLoading(false)
    return setShowTransactions(true)
  }

  return (
    <>
      <div className={['p-4 flex gap-4 bg-gray-100/5 border border-gray-800', loading && 'bg-animated from-gray-900 to-gray-800'].join(' ')}>
        <img src={imageUrl} className="mt-1 w-12 h-12 aspect-square" />
        <div className="flex flex-col gap-1 justify-center">

          {/* Plan name */}
          <p className="font-bold">{planName}</p>

          {children}

          {/* Transactions link and list */}
          {transactionIds.length > 0 && <button className="mt-2 text-left text-xs underline text-gray-400" onClick={handleGetTransactions}>View transactions</button>}
          <div className={['grid text-xs gap-2', showTransactions && 'pt-4'].join(' ')}>
            {showTransactions && transactions?.map((t) => {
              return <Transaction key={t.id} transaction={t} />
            })}
          </div>
        </div>
        <span className="flex-1"></span>
        {actionButton}
      </div>

    </>
  )
}
PlanWrapper.propTypes = {
  imageUrl: PropTypes.string,
  planName: PropTypes.string,
  transactionIds: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  actionButton: PropTypes.array,
}

const billingApiBase = import.meta.env.VITE_BILLING_FUNCTION_BASE

const Transaction = ({ transaction }) => {
  return (
    <div className="flex gap-4">
      <p className="font-semibold">{format(new Date(transaction.billed_at), 'd MMM, yyyy')}</p>
      <p>{(transaction.details.totals.grand_total / 100).toLocaleString('en-US', { style: 'currency', currency: transaction.details.totals.currency_code })}</p>
      <a href={`${billingApiBase}/transactions/${transaction.id}/invoice`} className="underline">Download invoice</a>
    </div>
  )
}

Transaction.propTypes = {
  transaction: PropTypes.object,
}
