import ModalWrapper from './ModalWrapper.jsx'
import PropTypes from 'prop-types'
import ShareableLinkAndAccessCode from './partials/ShareableLinkAndAccessCode.jsx'
import UpgradeMessage from '../UpgradeMessage.jsx'
import ToggleButton from '../interactives/ToggleButton.jsx'
import { useCallback, useEffect, useState } from 'react'
import { faUserLock, faUserPen } from '@fortawesome/free-solid-svg-icons'
import { GUEST_EDIT } from '../../constants/billingTypes.js'
import { useAtomValue } from 'jotai'
import { planAtom } from '../../store/plan.store.js'
import { generateEventLinkWithSignature } from '../../utils/generateLinkWithSignature.js'

const accessTypes = {
  READ_ACCESS: 'READ',
  FULL_ACCESS: 'FULL',
}

export default function EventCollaborateModal({ eventId, open, setOpen }) {
  const { READ_ACCESS, FULL_ACCESS } = accessTypes
  const [accessType, setAccessType] = useState(READ_ACCESS)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState()
  const plan = useAtomValue(planAtom)

  const buildUrl = useCallback(async () => {
    setLoading(true)
    const newUrl = await generateEventLinkWithSignature(eventId, accessType == FULL_ACCESS)
    setUrl(newUrl)
    setLoading(false)
  }, [eventId, accessType])

  useEffect(() => {
    if (open) buildUrl()
  }, [open, buildUrl])

  return (
    <ModalWrapper
      title="Invite Guests"
      open={[open, setOpen]}
    >
      <p className="text-gray-400 font-light">Choose if guests have full access to edit cues, columns and run the show</p>

      <ToggleButton
        options={[
          {
            value: READ_ACCESS,
            label: 'Only read access',
            icon: faUserLock,
          },
          {
            value: FULL_ACCESS,
            label: 'Full read and write access',
            icon: faUserPen,
            disabled: !plan.features.includes(GUEST_EDIT),
          },
        ]}
        selected={accessType}
        updateSelected={setAccessType}
      />
      <div className="text-right">{!plan.features.includes(GUEST_EDIT) && <UpgradeMessage message="Upgrade your account to give write access." />}</div>
      <div className="mt-6 space-y-2">
        <p className="text-gray-400 font-light">
          Share with
          {accessType === READ_ACCESS ? 'read' : 'full'}
          {' '}
          access
        </p>
        <ShareableLinkAndAccessCode url={url} loading={loading} />
      </div>
    </ModalWrapper>
  )
}

EventCollaborateModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}
