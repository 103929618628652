import PropTypes from 'prop-types'
import isValidDate from '../../utils/isValidDate.js'
import { floorSeconds } from '../../utils/formatTime.js'
import { parse, format } from '@rundown-studio/timeutils'

export default function TimeInput ({
  className = '',
  title,
  timezone,
  time,
  updateTime,
  ...props
}) {
  const parsedTime = new Date(time)

  function onChange (event) {
    const newTime = fromZonedInput(event.target.value, timezone)
    if (!isValidDate(newTime)) return
    if (Number(time) === Number(newTime)) return
    updateTime(newTime)
  }

  return (
    <input
      data-label="time-input"
      className={[
        'enabled:cursor-text transition-colors px-1 text-gray-300 font-mono bg-transparent rounded-sm text-left focus:outline-none',
        className,
      ].join(' ')}
      title={title ? String(title) : ''}
      type="time"
      value={toZonedInput(parsedTime, timezone)}
      onChange={onChange}
      step="2"
      {...props}
    />
  )
}

/**
 * [toZonedInput description]
 * @param  {Date} time
 * @param  {string} [timezone]
 * @return {string}
 */
function toZonedInput (time, timezone) {
  if (!isValidDate(time)) return null
  const flooredTime = floorSeconds(time.getTime())
  // return formatInTimeZone(flooredTime, timezone, 'HH:mm:ss')
  return format(flooredTime, 'HH:mm:ss', { timezone })
}

function fromZonedInput (timeStr, timezone) {
  const pattern = timeStr.split(':').length === 3 ? 'HH:mm:ss' : 'HH:mm'
  const time = parse(timeStr, pattern, new Date(), { timezone })
  const out = new Date(floorSeconds(time))
  return out
}

TimeInput.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  timezone: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  /**
   * `updateTime` is called with an object
   * @type {object} payload
   * @type {Date} payload.time – new time (normized with today's calender date)
   * @type {number} payload.diff – normalized difference between old and new date in milliseconds
   */
  updateTime: PropTypes.func.isRequired,
}
