import { faArchive, faEllipsis, faFolder, faRotateLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { deleteEvent, updateEvent as updateEventFirestore } from '../../../firestore'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../../../store/toast.store'
import { removeEventAtom, updateEventAtom } from '../../../store/eventList.store'
import ModalWrapper from '../../modal/ModalWrapper'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip'
import { Menu, MenuItem } from '../../interactives/DropdownMenu.jsx'

export default function EventCard ({ event, rundowns, archivedCount = 0 }) {
  const { teamId } = useParams()
  const [loading, setLoading] = useState(false)
  const [eventToDelete, setEventToDelete] = useState()
  const addToast = useSetAtom(toastAtom)
  const removeEvent = useSetAtom(removeEventAtom)
  const updateEvent = useSetAtom(updateEventAtom)

  const navigate = useNavigate()

  async function handleDeleteEvent () {
    setLoading(true)
    await deleteEvent(event.id)
    setEventToDelete(null)
    setLoading(false)
    addToast({ title: 'Event has been deleted', type: 'success' })
    return removeEvent(event.id)
  }

  return (
    <div
      className="relative group"
      data-label="event-card"
    >
      <button
        type="button"
        disabled={event.archivedAt}
        onClick={() => navigate(`/dashboard/${teamId}/event/${event.id}`)}
        className={[
          'h-[8rem] flex text-left w-full flex-col space-y-3 text-lg bg-gray-900 rounded group-hover:brightness-110',
          loading && 'bg-animated from-gray-900 to-black',
        ].join(' ')}
      >
        <div className="px-4 py-2 flex w-full rounded-t bg-gray-900 gap-2 items-center border-b-2 border-gray-800">
          <FontAwesomeIcon icon={faFolder} />
          {event.logo
            ? <img src={event.logo} className="max-h-[20px]" />
            : <p className="text-sm">Event</p>}
        </div>
        <div className="px-4 line-clamp-1" title={event.name}>{event.name}</div>
        <div>
          <Tooltip placement="bottom">
            <TooltipTrigger className="inline-block text-xs text-gray-500 space-x-3">
              <span className="px-4 text-xs text-gray-500">
                {rundowns.length}
                {' '}
                rundown
                {rundowns.length === 1 ? '' : 's'}
              </span>
            </TooltipTrigger>
            <TooltipContent className="text-xs">
              <ul>
                {rundowns.map((r) => <li key={r.id}>{r.name}</li>)}
              </ul>
              {!rundowns.length && <p>No rundowns</p>}
              {archivedCount > 0 && (
                <p className="mt-1 text-white/70">
                  +
                  {archivedCount}
                  {' '}
                  archived rundown
                  {archivedCount > 1 && 's'}
                </p>
              )}
            </TooltipContent>
          </Tooltip>
        </div>
      </button>

      {/* OPTIONS CONTEXT MENU */}
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity z-10">
        <Menu icon={faEllipsis} placement="right-start" className="!bg-gray-800">
          {event.archivedAt
            ? (
                <MenuItem
                  label="Restore"
                  icon={faRotateLeft}
                  onClick={async () => {
                    setLoading(true)
                    const { data } = await updateEventFirestore(event.id, { archivedAt: null })
                    setLoading(false)
                    addToast({ title: 'Event has been restored', type: 'success' })
                    return updateEvent(data)
                  }}
                />
              )
            : (
                <MenuItem
                  label="Archive"
                  icon={faArchive}
                  onClick={async () => {
                    setLoading(true)
                    const { data } = await updateEventFirestore(event.id, { archivedAt: new Date() })
                    setLoading(false)
                    addToast({ title: 'Event has been archived', type: 'success' })
                    return updateEvent(data)
                  }}
                />
              )}
          <MenuItem
            label="Delete"
            icon={faTrash}
            onClick={() => setEventToDelete(event)}
          />
        </Menu>
      </div>

      <ModalWrapper
        open={[eventToDelete, setEventToDelete]}
        onHide={() => setEventToDelete(null)}
        title={eventToDelete?.name}
        working={loading}
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setEventToDelete(null),
          },
          {
            text: `Delete Event${rundowns.length ? ' and Rundowns' : ''}`,
            icon: faTrash,
            css: 'bg-red-500',
            onClick: () => handleDeleteEvent(eventToDelete?.id),
          },
        ]}
      >
        <p>
          Are you sure you want to delete this event
          {rundowns.length ? ' and these rundowns' : ''}
          ?
        </p>
        <ul className="mt-2">
          {
            rundowns?.map((rundown) => {
              return (
                <li key={rundown.id} className="list-inside list-disc text-sm text-gray-300">{rundown.name}</li>
              )
            })
          }
        </ul>
      </ModalWrapper>

    </div>
  )
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  rundowns: PropTypes.array,
  archivedCount: PropTypes.number,
}
