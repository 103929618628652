import { format } from 'date-fns'
import { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { faArchive, faClone, faEllipsis, faFolder, faFolderMinus, faFolderPlus, faRotateLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { deleteRundown, duplicateRundown, updateRundown } from '../../../firestore'
import { useSetAtom } from 'jotai'
import { addRundownAtom, removeRundownAtom, updateRundownAtom } from '../../../store/rundownList.store'
import ModalWrapper from '../../modal/ModalWrapper'
import { toastAtom } from '../../../store/toast.store'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip'
import { formatCalendarDate } from '../../../utils/formatTime.js'
import { Menu, MenuItem, MenuSeparator } from '../../interactives/DropdownMenu.jsx'
import { formatTimeOfDay, formatTimezone } from '@rundown-studio/timeutils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function RundownCard ({
  rundown,
  eventList = [],
  overwriteLink,
  disabled = false,
  small = false,
}) {
  const { teamId } = useParams()
  const [loading, setLoading] = useState(false)
  const [rundownToDelete, setRundownToDelete] = useState(null)
  const setRundownAtom = useSetAtom(updateRundownAtom)
  const addRundown = useSetAtom(addRundownAtom)
  const removeRundown = useSetAtom(removeRundownAtom)
  const addToast = useSetAtom(toastAtom)

  const navigate = useNavigate()

  async function handleDeleteRundown () {
    setLoading(true)
    await deleteRundown(rundown.id)
    setRundownToDelete(null)
    setLoading(false)
    addToast({ title: 'Rundown has been deleted', type: 'success' })
    return removeRundown(rundown.id)
  }

  async function handleUpdateRundown (update) {
    const { data } = await updateRundown(rundown.id, update)
    return setRundownAtom(data)
  }

  async function handleRestore () {
    setLoading(true)
    try {
      await handleUpdateRundown({ archivedAt: null })
      addToast({ title: 'Rundown has been restored', type: 'success' })
    } finally {
      setLoading(false)
    }
  }

  async function handleDuplicate () {
    setLoading(true)
    try {
      const { data } = await duplicateRundown(rundown.id, teamId)
      addRundown(data)
    } finally {
      setLoading(false)
    }
  }

  async function handleRemoveFromEvent () {
    setLoading(true)
    try {
      await handleUpdateRundown({ eventId: null })
      addToast({ title: 'Rundown removed from your event', type: 'success' })
    } finally {
      setLoading(false)
    }
  }

  async function handleAddToEvent (eventId) {
    setLoading(true)
    try {
      await handleUpdateRundown({ eventId })
      addToast({ title: 'Rundown added to your event', type: 'success' })
    } finally {
      setLoading(false)
    }
  }

  async function handleArchive () {
    setLoading(true)
    try {
      await handleUpdateRundown({ archivedAt: new Date() })
      addToast({ title: 'Rundown has been archived', type: 'success' })
    } finally {
      setLoading(false)
    }
  }

  const startsAt = new Date(rundown.startTime)
  const createdAt = new Date(rundown.createdAt)
  const updatedAt = new Date(rundown.updatedAt)
  const tz = rundown.timezone
  const cueCount = countCues(rundown.cues)

  const status = useMemo(() => {
    return {
      'imported': { label: 'Imported', css: 'text-gray-500 border-dashed' },
      'draft': { label: 'Draft', css: 'text-gray-400' },
      'awaiting-data': { label: 'Awaiting data', css: 'text-orange-500' },
      'approved': { label: 'Approved', css: 'text-green-500' },
      'finalized': { label: 'Finalized', css: 'text-blue-500' },
      'rejected': { label: 'Rejected', css: 'text-red-500' },
    }[rundown?.status || 'draft']
  }, [rundown?.status])

  return (
    <div
      className="relative group"
      data-label="rundown-card"
    >
      <button
        type="button"
        disabled={rundown.archivedAt}
        onClick={() => {
          if (overwriteLink) {
            window.location.href = overwriteLink
          } else {
            navigate(`/rundown/${rundown.id}`)
          }
        }}
        className={[
          'p-4 flex flex-col text-left w-full justify-between text-lg bg-gray-900 rounded group-hover:brightness-110',
          loading && 'bg-animated from-gray-900 to-black',
          !small && 'h-[8rem]',
        ].join(' ')}
      >
        {rundown.archivedAt && rundown.eventId && (
          <div className="text-xs text-white/60">
            <FontAwesomeIcon icon={faFolder} />
            {' '}
            {eventList.find((e) => e.id === rundown.eventId).name}
          </div>
        )}
        <div
          className={['line-clamp-2', (rundown.archivedAt && rundown.eventId ? 'leading-6' : '')].join(' ')}
          title={rundown.name}
        >
          {rundown.name}
        </div>
        <div className="flex items-center justify-between text-xs w-full">
          <Tooltip placement="bottom">
            <TooltipTrigger
              className="shrink truncate space-x-1 text-gray-500"
            >
              <span>{ format(startsAt, 'd MMM yy') }</span>
              { tz && (
                <span>
                  (
                  {formatTimezone(tz, 'abbr')}
                  )
                </span>
              ) }
              <span>&middot;</span>
              <span>
                { cueCount }
                {' '}
                { cueCount === 1 ? 'Cue' : 'Cues' }
              </span>
            </TooltipTrigger>
            <TooltipContent className="text-xs">
              <table>
                <tbody>
                  <tr>
                    <th className="text-left pr-2">Start:</th>
                    <td>
                      {formatCalendarDate(startsAt)}
                      {' '}
                      {formatTimeOfDay(startsAt)}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left pr-2">Timezone:</th>
                    <td>{ tz ? `${formatTimezone(tz, 'long')}, ${formatTimezone(tz, 'offset')}` : 'UTC' }</td>
                  </tr>
                  <tr>
                    <th className="text-left pr-2">Size:</th>
                    <td>
                      { cueCount }
                      {' '}
                      { cueCount === 1 ? 'Cue' : 'Cues' }
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr className="border-gray-600 my-2" />
              <p>
                Created at
                {formatCalendarDate(createdAt)}
                {' '}
                {formatTimeOfDay(createdAt)}
              </p>
              <p>
                Updated at
                {formatCalendarDate(updatedAt)}
                {' '}
                {formatTimeOfDay(updatedAt)}
              </p>
            </TooltipContent>
          </Tooltip>
          <div className="grow basis-1" />
          <div className="flex-1"></div>
          <div className={['border border-current rounded px-1 whitespace-nowrap', status.css].join(' ')}>
            {status.label}
          </div>
        </div>
      </button>

      {/* OPTIONS CONTEXT MENU */}
      { !disabled && (
        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity">
          {rundown.archivedAt
            ? (
                <Menu
                  icon={faEllipsis}
                  placement="right-start"
                  className="!bg-gray-800"
                  data-label="context-menu-btn"
                >
                  <MenuItem
                    label="Restore"
                    icon={faRotateLeft}
                    onClick={handleRestore}
                  />
                  <MenuItem
                    label="Delete"
                    icon={faTrash}
                    onClick={() => setRundownToDelete(rundown)}
                  />
                </Menu>
              )
            : (
                <Menu
                  icon={faEllipsis}
                  placement="right-start"
                  className="!bg-gray-800"
                  data-label="context-menu-btn"
                >
                  <MenuItem
                    label="Duplicate"
                    icon={faClone}
                    onClick={handleDuplicate}
                  />
                  {rundown.eventId
                    ? (
                        <MenuItem
                          label="Remove from event"
                          icon={faFolderMinus}
                          onClick={handleRemoveFromEvent}
                        />
                      )
                    : (
                        <Menu label="Add to event" disabled={!eventList.length} icon={faFolderPlus}>
                          {eventList.map((e) => (
                            <MenuItem
                              key={e.id}
                              label={e.name}
                              onClick={() => handleAddToEvent(e.id)}
                            />
                          ))}
                        </Menu>
                      )}
                  <MenuSeparator />
                  <MenuItem
                    label="Archive"
                    icon={faArchive}
                    onClick={handleArchive}
                  />
                  <MenuItem
                    label="Delete"
                    icon={faTrash}
                    onClick={() => setRundownToDelete(rundown)}
                  />
                </Menu>
              )}
        </div>
      )}

      <ModalWrapper
        open={[rundownToDelete, setRundownToDelete]}
        onHide={() => setRundownToDelete(null)}
        title={rundownToDelete?.name}
        working={loading}
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setRundownToDelete(null),
          },
          {
            text: 'Delete Rundown',
            icon: faTrash,
            css: 'bg-red-500',
            onClick: () => handleDeleteRundown(rundownToDelete?.id),
          },
        ]}
        dataLabel="delete-rundown-modal"
      >
        <p>Are you sure you want to delete this rundown?</p>
      </ModalWrapper>

    </div>
  )
}

RundownCard.propTypes = {
  rundown: PropTypes.object.isRequired,
  eventList: PropTypes.array,
  overwriteLink: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
}

function countCues (cues) {
  return cues.reduce((total, cue) => {
    let count = 1
    if (cue.children && cue.children.length) {
      count += countCues(cue.children)
    }
    return total + count
  }, 0)
}
