import TransportControls from './TransportControls.jsx'
import TimeAndOverUnder from './TimeAndOverUnder.jsx'
import ColumnHeaders from './ColumnHeaders.jsx'
import EndShowModal from '../../modal/EndShowModal.jsx'
import { endRunnerTimesnap, pauseRunnerTimesnap, createRundownRunner, unsetRundownRunner, startRunnerTimesnap, updateRundownCue } from '../../../firestore.js'
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import { useSetAtom } from 'jotai'
import { toggleCollapsedGroupsAtom } from '../../../store/rundown.store.js'

export default function RundownHeader ({
  rundown,
  runner,
  cues,
  columns,
  hiddenColumns,
  plan,
  readonly,
}) {
  const [runnerLoading, setRunnerLoading] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(true)
  const toggleCollapsedGroups = useSetAtom(toggleCollapsedGroupsAtom)

  async function onPlay() {
    setRunnerLoading(true)
    await startRunnerTimesnap(rundown.id, rundown.runnerId)
      .finally(() => setRunnerLoading(false))
  }

  async function onPause() {
    setRunnerLoading(true)
    await pauseRunnerTimesnap(rundown.id, rundown.runnerId)
      .finally(() => setRunnerLoading(false))
  }

  async function onNext() {
    setRunnerLoading(true)
    await startRunnerTimesnap(rundown.id, rundown.runnerId, runner.nextCueId)
      .finally(() => setRunnerLoading(false))
  }

  async function onCreateRunner() {
    setRunnerLoading(true)
    await createRundownRunner(rundown.id, rundown.id)
      .finally(() => setRunnerLoading(false))
  }

  async function onUnsetRunner() {
    setRunnerLoading(true)
    await unsetRundownRunner(rundown.id, rundown.id)
      .finally(() => setRunnerLoading(false))
  }

  async function onEndRunner() {
    setRunnerLoading(true)
    await endRunnerTimesnap(rundown.id, rundown.runnerId)
      .finally(() => setRunnerLoading(false))
  }

  async function handleNewDuration(duration) {
    setRunnerLoading(true)
    await updateRundownCue(rundown.id, runner.timesnap.cueId, { duration })
    setRunnerLoading(false)
  }

  async function onAddRemoveTime(amount) {
    setRunnerLoading(true)
    const duration = Math.max(cues[runner.timesnap.cueId].duration + amount, 0)
    await updateRundownCue(rundown.id, runner.timesnap.cueId, { duration })
      .finally(() => setRunnerLoading(false))
  }

  /**
   * Trigger "End Show" Modal when any cue went 1h overtime
   * It's enough to trigger this modal only on first render. It's purpose is to avoid users opening a rundown that's
   * old and still running, and making edits to it while the UI updates every 100ms.
   */
  const [endShowModalOpen, setEndShowModalOpen] = useState(false)
  const endShowModalTriggered = useRef(false)
  useEffect(() => {
    if (!runner || endShowModalTriggered.current) return
    const is1hOvertime = (runner.timesnap.deadline - Date.now()) < (-1000 * 60 * 60)
    const isEnded = runner.timesnap.cueId === null
    if (is1hOvertime && !isEnded) {
      endShowModalTriggered.current = true
      setTimeout(() => setEndShowModalOpen(true), 1000)
    }
  }, [runner])

  return (
    <div className="h-full w-fit bg-black/90 backdrop-blur px-2 py-2 flex gap-1">
      <div
        className="flex-none w-7 flex items-end"
      >
        <Tooltip>
          <TooltipTrigger asChild={true}>
            <button
              className={[
                'flex-none w-full h-7 rounded opacity-70 text-gray-500 group-hover:opacity-70 hover:!opacity-100 transition-opacity text-xs focus:outline-none focus:ring',
              ].join(' ')}
              onClick={() => {
                toggleCollapsedGroups(toggleStatus)
                setToggleStatus(!toggleStatus)
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} className={['transition-all', toggleStatus ? '' : '-rotate-90'].join(' ')} />
            </button>
          </TooltipTrigger>
          <TooltipContent>
            {toggleStatus ? 'Collapse' : 'Expand'}
            {' '}
            all groups
          </TooltipContent>
        </Tooltip>
      </div>
      <div
        className="flex-none w-[610px] pr-2"
      >
        <TransportControls
          loading={runnerLoading}
          nextCueId={runner?.nextCueId}
          onStartShow={onCreateRunner}
          onEndShow={onEndRunner}
          onResetShow={onUnsetRunner}
          onNext={onNext}
          onPause={onPause}
          onPlay={onPlay}
          onAddRemoveTime={onAddRemoveTime}
          handleNewDuration={handleNewDuration}
          readonly={readonly}
        />
      </div>
      <div className="flex flex-col justify-between">
        <TimeAndOverUnder
          timezone={rundown.timezone}
        />
        <ColumnHeaders
          columns={columns}
          hiddenColumns={hiddenColumns}
          columnsOrder={rundown.columns}
          plan={plan}
        />
      </div>
      {!readonly && (
        <EndShowModal
          open={endShowModalOpen}
          onDismiss={() => setEndShowModalOpen(false)}
          onEndShow={() => {
            setEndShowModalOpen(false)
            onEndRunner()
          }}
        />
      )}
    </div>
  )
}

RundownHeader.propTypes = {
  rundown: PropTypes.object.isRequired,
  runner: PropTypes.object,
  cues: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array,
  plan: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
}
