import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import ImageExtension from './ImageExtension.jsx'

export default Node.create({
  name: 'imageComponent',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img-component',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img-component', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageExtension)
  },
})
