import { getTrackedUTMParams, clearUTMParams } from './utmTracker.js'
import { updateAirtable } from '../firestore.js'
import { captureException } from '../sentry.js'

/**
 * Persist UTM parameters to Airtable is available
 * @return {void}
 */
export default async function commitUTMParams () {
  const utmParams = getTrackedUTMParams()
  if (Object.keys(utmParams).length === 0) return
  try {
    const params = Object.values(utmParams).join(', ')
    await updateAirtable({ payload: { UTM: params }, append: true })
    clearUTMParams()
  } catch (err) {
    captureException(err)
  }
}
