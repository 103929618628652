import ModalWrapper from './ModalWrapper.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

export default function EndShowModal({ open, onDismiss, onEndShow }) {
  return (
    <ModalWrapper
      open={[open, onDismiss]}
      title="End Show?"
      width={500}
      buttons={[
        {
          text: 'Keep Running',
          onClick: onDismiss,
          colour: 'dark',
        },
        {
          text: 'End Show',
          onClick: onEndShow,
          colour: 'dark',
        },
      ]}
    >
      <div className="space-y-4 text-gray-300">
        <p>
          This show has been running for a long time past its scheduled end.
          Would you like to end it now?
        </p>
        <div className="bg-gray-800/50 border border-gray-800 rounded-md p-3 flex gap-3">
          <FontAwesomeIcon icon={faInfoCircle} size="xs" className="flex-none text-gray-600 mt-0.5" />
          <p className="text-xs text-gray-400">
            Over/under times will remain visible for review until you click &quot;Reset rundown timing&quot;.
            Ending the show will only stop the timer and clear the current cue.
          </p>
        </div>
      </div>
    </ModalWrapper>
  )
}

EndShowModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onEndShow: PropTypes.func.isRequired,
}
