import AdvancedSelect from './AdvancedSelect.jsx'
import PropTypes from 'prop-types'
import formatTimezone from '../../utils/formatTimezone.js'
import timezoneFallback from '../../utils/timezoneFallback.json'

export default function TimezoneSelect ({
  className = '',
  value,
  setValue,
  ...props
}) {
  const options = _getTimezoneOptions()

  return (
    <AdvancedSelect
      className={className}
      options={options}
      value={value}
      setValue={setValue}
      placeholder="Choose a timezone..."
      {...props}
    />
  )
}

TimezoneSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
}

function _getTimezoneOptions () {
  let timezones = []
  try {
    timezones = Intl.supportedValuesOf('timeZone')
  } catch {
    timezones = timezoneFallback
  }
  timezones = timezones
    .filter((tz) => tz.includes('/') && !tz.includes('GMT'))
  timezones.unshift('UTC')
  return timezones.map(_generateOption)
}

function _generateOption (tz) {
  return {
    value: tz,
    label: formatTimezone(tz, 'city'),
    detail: [
      formatTimezone(tz, 'offset'),
      formatTimezone(tz, 'long'),
      formatTimezone(tz, 'abbr'),
    ].join(' | '),
  }
}
