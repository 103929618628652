import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

export default function CreateNew ({ text = 'Create new rundown', handleOnCreate, className, icon = faPlusCircle }) {
  return (
    <button
      to="/create"
      onClick={handleOnCreate}
      className={[
        'p-2 h-[8rem] flex flex-col items-center justify-center gap-2 text-lg bg-transparent border-2 border-dashed border-gray-700 rounded hover:brightness-110 hover:border-gray-500',
        className,
      ].join(' ')}
    >
      <span className="text-blue-500 text-3xl">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{text}</span>
    </button>
  )
}

CreateNew.propTypes = {
  text: PropTypes.string,
  handleOnCreate: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.object,
}
