import Layout from '../components/Layout'
import * as PaddleService from '../utils/PaddleService.js'
import { useEffect } from 'react'

export default function PaddleRetainTest () {
  useEffect(() => {
    async function initPaddle() {
      const paddle = await PaddleService.get()
      console.info('Paddle Instance', paddle)
    }

    initPaddle()
  }, [])

  return (
    <Layout title="Paddle Retain Test Page">
      <div className="my-16 text-center">
        (Open Browser Console)
      </div>
    </Layout>
  )
}
