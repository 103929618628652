import PropTypes from 'prop-types'
import { useCurrentEditor } from '@tiptap/react'
import { useEffect, useRef } from 'react'

/**
 * Update TipTap editor props after the editor was instantiated.
 */
export default function TipTapPropsUpdater ({ editable }) {
  const { editor } = useCurrentEditor()
  const isFirstRender = useRef(true)

  // Runs on changes to `content`
  useEffect(() => {
    // Skip the effect on the first render
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    // Update the prop if it differs
    if (editor.isEditable !== editable) editor.setEditable(editable)
  }, [editor, editable])

  // This component does not render anything to the DOM.
  return null
}

TipTapPropsUpdater.propTypes = {
  editable: PropTypes.bool,
}
