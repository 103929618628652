import { auxApi, rundownApi, runnerApi, teamApi, billingApi, eventApi } from './axios.js'

let toastAdder = null
export function injectAddToast(addToast) {
  toastAdder = addToast
}

async function _errorHandler(promise) {
  try {
    return await promise
  } catch (err) {
    toastAdder({
      title: String(err.response?.data?.message || err.response?.data || err),
      body: [`Server responded with status ${err.response?.status}`, err.response?.statusText].filter(Boolean).join(' - '),
      type: 'fail',
    })
    throw err
  }
}

//
// RUNDOWN
//

export const getRundownsByTeam = async (teamId) => {
  return await _errorHandler(rundownApi.get(`/get-by-team/${teamId}`))
}

export const createRundown = async (body) => {
  return await _errorHandler(rundownApi.post('/', body))
}

export const getRundown = async (rundownId) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}`))
}
export const getRundownEvent = async (rundownId) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}/event`))
}

export const updateRundown = async (rundownId, update) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}`, update))
}

export const duplicateRundown = async (rundownId, teamId) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/clone`, { teamId }))
}

export const updateRundownCell = async (rundownId, cellId, update) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/cells/${cellId}`, update))
}

export const createRundownCell = async (rundownId, content) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/cells`, content))
}

export const updateRundownCue = async (rundownId, cueId, update) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/cues/${cueId}`, update))
}

export const updateRundownCues = async (rundownId, cueIds, update) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/cues`, { cueIds, update: update }))
}

export const addRundownCue = async (rundownId, params = {}, data = {}) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/cues`, data, { params }))
}

export const duplicateRundownCue = async (rundownId, cueId) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/cues/${cueId}/clone`))
}

export const duplicateRundownCues = async (rundownId, cueIds) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/cues/clone`, { cueIds }))
}

export const deleteRundownCue = async (rundownId, cueId) => {
  return await _errorHandler(rundownApi.delete(`/${rundownId}/cues/${cueId}`))
}

export const deleteRundownCues = async (rundownId, cueIds) => {
  return await _errorHandler(rundownApi.delete(`/${rundownId}/cues`, { data: { cueIds } }))
}

export const addRundownColumn = async (rundownId, params) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/columns`, null, { params }))
}

export const updateRundownColumn = async (rundownId, columnId, update) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/columns/${columnId}`, update))
}

export const deleteRundownColumn = async (rundownId, columnId) => {
  return await _errorHandler(rundownApi.delete(`/${rundownId}/columns/${columnId}`))
}

export const reorderRundownColumns = async (rundownId, columns) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/reorderColumns`, columns))
}

export const reorderRundownCues = async (rundownId, cues) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/reorderCues`, cues))
}

export const moveRundownCues = async (rundownId, selectedCues, destination) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/cues/move`, { selectedCues, destination }))
}

export const groupRundownCues = async (rundownId, beforeCueId, cuesToGroupIds) => {
  return await _errorHandler(rundownApi.patch(`/${rundownId}/groupCues`, { beforeCueId, cuesToGroupIds }))
}

export const unsetRundownRunner = async (rundownId) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/unset-runner`))
}

export const createRundownRunner = async (rundownId) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/create-runner`))
}

export const deleteRundown = async (rundownId) => {
  return await _errorHandler(rundownApi.delete(`/${rundownId}`))
}

export const createRundownSignature = async (rundownId, text) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/create-signature`, { text }))
}

export const revokeRundownSignatures = async (rundownId) => {
  return await _errorHandler(rundownApi.post(`/${rundownId}/revoke-signatures`))
}

export const uploadRundownFile = async (rundownId, file, onProgress = () => {}) => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event) => onProgress(Math.round((event.loaded / event.total) * 100)),
  }
  return await _errorHandler(rundownApi.post(`/${rundownId}/upload-file`, formData, config))
}

export const rundownPlan = async (rundownId) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}/plan`))
}

export const generatePdf = async (rundownId, orientation, columnsToExport) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}/export-pdf`, { params: { orientation, columnsToExport } }))
}

export const generateHtml = async (rundownId, columnsToExport) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}/export-html`, { params: { columnsToExport } }))
}

export const generateCsv = async (rundownId) => {
  return await _errorHandler(rundownApi.get(`/${rundownId}/export-csv`, { params: { } }))
}

//
// RUNNER
//

export const startRunnerTimesnap = async (rundownId, runnerId, cueId) => {
  return await _errorHandler(runnerApi.post(`/${rundownId}/${runnerId}/timesnap/start`, null, { params: { cueId } }))
}

export const pauseRunnerTimesnap = async (rundownId, runnerId) => {
  return await _errorHandler(runnerApi.post(`/${rundownId}/${runnerId}/timesnap/pause`))
}

export const endRunnerTimesnap = async (rundownId, runnerId) => {
  return await _errorHandler(runnerApi.post(`/${rundownId}/${runnerId}/timesnap/end`))
}

export const updateRunner = async (rundownId, runnerId, update) => {
  return await _errorHandler(runnerApi.put(`/${rundownId}/${runnerId}`, update))
}

//
// TEAM
//

export const teamsByUid = async (uid) => {
  return await _errorHandler(teamApi.get(`/teams-by-uid/${uid}`))
}

export const getTeam = async (teamId) => {
  return await _errorHandler(teamApi.get(`/${teamId}`))
}

export const updateTeam = async (teamId, update) => {
  return await _errorHandler(teamApi.patch(`/${teamId}`, update))
}

export const membersMetadata = async (teamId) => {
  return await _errorHandler(teamApi.get(`/${teamId}/members/meta`))
}

export const inviteTeamMember = async (teamId, teamMember) => {
  return await _errorHandler(teamApi.post(`/${teamId}/members`, teamMember))
}

export const acceptTeamInvite = async (teamId) => {
  return await _errorHandler(teamApi.post(`/${teamId}/accept-invite`))
}

export const resendInvite = async (teamId, email) => {
  return await _errorHandler(teamApi.post(`/${teamId}/resend-invite`, { email }))
}

export const removeTeamMember = async (teamId, emailOrUid) => {
  return await _errorHandler(teamApi.delete(`/${teamId}/members/${emailOrUid}`))
}

export const teamPlan = async (teamId) => {
  return await _errorHandler(teamApi.get(`/${teamId}/plan`))
}

export const importFile = async (teamId, file, { timezone, autoAddHeadings, autoAddBgColors }, onProgress = () => {}) => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event) => onProgress(Math.round((event.loaded / event.total) * 100)),
    params: { timezone, autoAddHeadings, autoAddBgColors },
  }
  return await _errorHandler(teamApi.post(`/${teamId}/import`, formData, config))
}

export const generateApiToken = async (teamId) => {
  return await _errorHandler(teamApi.post(`/${teamId}/generate-api-token`))
}

export const cancelSubscription = async (teamId, subscriptionId) => {
  return await _errorHandler(teamApi.post(`/${teamId}/subscriptions/${subscriptionId}/cancel`))
}

//
// AUX
//

export const getValidCode = async (codeId) => {
  return await _errorHandler(auxApi.get(`/code/${codeId}`))
}

export const createCode = async (url) => {
  return await _errorHandler(auxApi.post('/code', { url }))
}

export const updateAirtable = async (data) => {
  return await _errorHandler(auxApi.post('/update-airtable', data))
}

export const newsletterSubscribe = async () => {
  return await _errorHandler(auxApi.post('/newsletter-subscribe'))
}

//
// BILLING
//

export const getTransactions = async (transactionId) => {
  return await _errorHandler(billingApi.get(`/transactions?transactionId=${transactionId}`))
}

export const getTransactionInvoice = async (transactionId) => {
  return await _errorHandler(billingApi.post(`/transactions/${transactionId}/invoice`))
}

//
// EVENT
//

export const getEventsByTeam = async (teamId) => {
  return await _errorHandler(eventApi.get(`/get-by-team/${teamId}`))
}

export const updateEvent = async (eventId, update) => {
  return await _errorHandler(eventApi.patch(`/${eventId}`, update))
}

export const createEvent = async (body) => {
  return await _errorHandler(eventApi.post('/', body))
}

export const getEvent = async (eventId, params) => {
  return await _errorHandler(eventApi.get(`/${eventId}`, { params }))
}

export const getEventRundowns = async (eventId, params) => {
  return await _errorHandler(eventApi.get(`/${eventId}/rundowns`, { params }))
}
export const getEventRundownLinks = async (eventId, params) => {
  return await _errorHandler(eventApi.get(`/${eventId}/rundown-links`, { params }))
}

export const uploadEventFile = async (eventId, file, onProgress = () => {}) => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event) => onProgress(Math.round((event.loaded / event.total) * 100)),
  }
  return await _errorHandler(eventApi.post(`/${eventId}/upload-file`, formData, config))
}

export const deleteEvent = async (eventId) => {
  return await _errorHandler(eventApi.delete(`/${eventId}`))
}

export const createEventSignature = async (eventId, text) => {
  return await _errorHandler(eventApi.post(`/${eventId}/create-signature`, { text }))
}

export const revokeEventSignatures = async (eventId) => {
  return await _errorHandler(eventApi.post(`/${eventId}/revoke-signatures`))
}
