import DashboardWrapper from './DashboardWrapper'
import { useState } from 'react'
import Button from '../Button'
import { faEye, faEyeSlash, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { generateApiToken } from '../../firestore'
import { useOutletContext } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { userTeamAtom } from '../../store/user.store'
import useIsTeamAdmin from '../hooks/useIsTeamAdmin'

export default function API () {
  const {
    team,
  } = useOutletContext()
  const setTeamAtom = useSetAtom(userTeamAtom)

  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState(team.apiToken)
  const [tokenHidden, setTokenHidden] = useState(true)
  const [copied, setCopied] = useState(false)

  const isTeamAdmin = useIsTeamAdmin()

  const handleGenerateApiToken = async () => {
    setLoading(true)
    setToken('')
    try {
      const { data } = await generateApiToken(team.id)
      setTeamAtom(data)
      setToken(data.apiToken)
      setLoading(false)
    } catch {
      // Ignore error
    }
    setLoading(false)
  }

  const handleCopyToClipboard = (e) => {
    e.target.select()
    setTokenHidden(false)
    navigator.clipboard.writeText(token)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, [3000])
  }
  return (
    <DashboardWrapper title="API">
      <p className="text-gray-300 text-sm">
        Control Rundown Studio via our API. Read about how this works
        {' '}
        <a className="underline" href="https://rundownstudio.app/docs/rundown/api/" target="_blank" rel="noreferrer">in our docs</a>
        .
      </p>

      <div className="mt-4 flex flex-col gap-4 lg:w-1/2">
        <h2>API Token</h2>
        <div
          className={[
            'relative flex-grow flex rounded text-sm',
            token ? 'font-bold' : 'font-normal',
            loading ? 'bg-animated from-gray-800 to-gray-600 text-white/70' : '',
            tokenHidden ? 'bg-gray-900/50' : 'bg-gray-900',
          ].join(' ')}
        >
          {token && tokenHidden
          && (
            <div className="absolute z-10 w-full h-full left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-center">
              <Button
                icon={tokenHidden ? faEye : faEyeSlash}
                text={tokenHidden ? 'Reveal' : 'Hide'}
                colour="dark"
                onClick={() => setTokenHidden(!tokenHidden)}
                loading={loading}
              />
            </div>
          )}
          <textarea
            value={tokenHidden ? tokenToHidden(token) : token}
            placeholder={loading ? 'Generating token...' : 'No token generated'}
            onClick={(e) => {
              handleCopyToClipboard(e)
            }}
            readOnly
            disabled={loading || !token}
            rows={4}
            className={[
              'bg-transparent w-full p-3 focus:outline-none rounded resize-none font-mono',
              tokenHidden && 'opacity-20',
            ].join(' ')}
            // onFocus={() => setTokenHidden(false)}
            onBlur={() => setTokenHidden(true)}
          />
        </div>
        <div className="flex gap-4 justify-between">
          <p className={['text-sm transition-opacity text-gray-200', copied ? 'opacity-100' : 'opacity-0'].join(' ')}>Copied to clipboard!</p>
          <span className="flex-1"></span>
          <Button
            icon={faRotateRight}
            text="Generate new token"
            colour="dark"
            onClick={handleGenerateApiToken}
            loading={loading}
            disabled={!isTeamAdmin}
            toolTip={!isTeamAdmin ? 'Only team admins can generate a new tokens.' : ''}
          />
        </div>
      </div>
    </DashboardWrapper>
  )
}

function tokenToHidden (token = '') {
  if (!token) return null
  const firstFourChars = token?.slice(0, 4)
  const remainingChars = token?.slice(4, -10)
  const lastTenChars = token?.slice(-10)
  const hiddenChars = remainingChars?.replace(/./g, '•')
  return `${firstFourChars}${hiddenChars}${lastTenChars}`
}
