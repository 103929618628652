import axios from 'axios'
import { getUser } from './firebase.js'
import { jwtDecode } from 'jwt-decode'

/**
 * Instantiate Axios instances
 */
export const baseApi = axios.create({ timeout: 30000 })
export const rundownApi = baseApi.create({ baseURL: import.meta.env.VITE_RUNDOWN_FUNCTION_BASE })
export const runnerApi = baseApi.create({ baseURL: import.meta.env.VITE_RUNNER_FUNCTION_BASE })
export const teamApi = baseApi.create({ baseURL: import.meta.env.VITE_TEAM_FUNCTION_BASE })
export const auxApi = baseApi.create({ baseURL: import.meta.env.VITE_AUX_FUNCTION_BASE })
export const billingApi = baseApi.create({ baseURL: import.meta.env.VITE_BILLING_FUNCTION_BASE })
export const eventApi = baseApi.create({ baseURL: import.meta.env.VITE_EVENT_FUNCTION_BASE })

/**
 * Add request interceptor to attach the firebase auth token.
 *
 * user.getIdToken()
 * > Returns the current token if it has not expired or if it will not expire in the next five minutes.
 * > Otherwise, this will refresh the token and return a new one.
 * See: https://firebase.google.com/docs/reference/js/auth.user.md#usergetidtoken
 */
async function useAuth(config) {
  const user = await getUser()
  if (user) config.headers['Authorization'] = await user.getIdToken()
  return config
}
baseApi.interceptors.request.use(useAuth)
rundownApi.interceptors.request.use(useAuth)
runnerApi.interceptors.request.use(useAuth)
teamApi.interceptors.request.use(useAuth)
auxApi.interceptors.request.use(useAuth)
billingApi.interceptors.request.use(useAuth)
eventApi.interceptors.request.use(useAuth)

/**
 * Store and handle rundown token
 */
export const RundownToken = {
  rundownId: null,
  href: null,
  signature: null,
  token: null,

  /**
   * Request a new rundown token
   * @return {Promise<void>}
   * @private
   */
  async requestToken () {
    if (!this.rundownId) {
      throw new Error('Missing parameter to get rundown token: rundownId')
    }

    const { data } = await rundownApi.post(`/${this.rundownId}/create-token`, {
      href: this.href,
      signature: this.signature,
    }, { skipToken: true })

    this.token = data.token
  },

  /**
   * Get a valid token, requesting a new one if necessary.
   * @return {string} - A JWT token
   * @public
   */
  async getToken () {
    if (!this.isValid) await this.requestToken()
    return this.token
  },

  /**
   * Set parameters for token requests.
   * @return {void}
   * @public
   */
  setParameters (rundownId, href, signature) {
    this.rundownId = rundownId
    this.href = href
    this.signature = signature
    this.token = null // Reset token to force a new request
  },

  /**
   * Check if the current token is valid.
   * Consider invalid if within 5 minutes of expiration.
   * @return {Boolean}
   * @public
   */
  get isValid () {
    if (!this.token) return false
    const decoded = jwtDecode(this.token)
    const exp = new Date(decoded.exp * 1000)
    return exp > new Date(Date.now() + 5 * 60 * 1000)
  },

  /**
   * Get the rundown access level of this token.
   * @return {RundownAccess}
   * @public
   */
  get access () {
    if (!this.token) return false
    const decoded = jwtDecode(this.token)
    return decoded.access
  },
}

/**
 * Add request interceptor for rundown token.
 * Skip token for requests with skipToken option to avoid invinite loops.
 */
async function useRundownToken (config) {
  if (config.skipToken) return config
  if (RundownToken.rundownId) {
    config.headers['Rundown-Token'] = await RundownToken.getToken()
  }
  return config
}
rundownApi.interceptors.request.use(useRundownToken)
runnerApi.interceptors.request.use(useRundownToken)
