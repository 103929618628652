import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import _keyBy from 'lodash/keyBy'
import { CUE_TYPE_CUE } from '../constants/cueTypes'

//
// Rundown
//
export const rundownAtom = atom(
  null,
  (get, set, updatedRundown) => {
    if (!updatedRundown) {
      set(rundownAtom, null)
      return
    }

    const parsedRundown = {
      ...updatedRundown,
      startTime: updatedRundown.startTime instanceof Date ? updatedRundown.startTime : new Date(updatedRundown.startTime),
      endTime: updatedRundown.endTime instanceof Date ? updatedRundown.endTime : new Date(updatedRundown.endTime),
    }

    set(rundownAtom, parsedRundown)
  },
)
export const clearRundownAtom = atom(
  null,
  (get, set) => {
    set(rundownAtom, null)
  },
)

//
// Rundown Cells
//
const _cellsInitAtom = atom(false)
export const cellsInitAtom = atom(
  (get) => get(_cellsInitAtom),
  (get, set) => {
    set(_cellsInitAtom, true)
  },
)

const _cellsAtom = atom({})
export const cellsAtom = atom(
  (get) => Object.values(get(_cellsAtom)),
  (get, set, updatedCells) => {
    set(_cellsAtom, _keyBy(updatedCells, 'id'))
  },
)
export const setCellsAtom = atom(
  null, // write-only atom
  (get, set, cellsArray) => {
    const cells = get(_cellsAtom)
    cellsArray.map((cell) => cells[cell.id] = cell)
    set(_cellsAtom, cells)
  },
)
export const setCellByIdAtom = atom(
  null, // write-only atom
  (get, set, cell) => {
    const cells = get(_cellsAtom)
    cells[cell.id] = cell
    set(_cellsAtom, cells)
  },
)

//
// Rundown Columns
//
const _columnsInitAtom = atom(false)
export const columnsInitAtom = atom(
  (get) => get(_columnsInitAtom),
  (get, set) => {
    set(_columnsInitAtom, true)
  },
)
const _columnsAtom = atom({})
// Getter: Returns an array of columns (sorted)
// Setter: Accepts an array of columns (unsorted)
export const columnsAtom = atom(
  (get) => {
    const rundown = get(rundownAtom)
    return Object.values(get(_columnsAtom))
      .filter((column) => rundown?.columns?.includes(column.id))
      .sort((a, b) => rundown?.columns?.indexOf(a.id) - rundown?.columns?.indexOf(b.id))
  },
  (get, set, updatedColumns) => {
    set(_columnsAtom, _keyBy(updatedColumns, 'id'))
  },
)
// Getter: Returns a getter for a columns by id
export const getColumnById = atom(
  (get) => (id) => get(_columnsAtom)[id],
  null,
)
// Setter: Accepts a new column width colId=string, newWidth=number
export const setColumnWidthAtom = atom(
  null,
  (get, set, colId, newWidth) => {
    const cols = get(_columnsAtom)
    cols[colId].width = newWidth
    set(_columnsAtom, _keyBy(cols, 'id'))
  },
)

//
// Rundown Cues
//
const _cuesInitAtom = atom(false)
export const cuesInitAtom = atom(
  (get) => get(_cuesInitAtom),
  (get, set) => {
    set(_cuesInitAtom, true)
  },
)
const _cuesAtom = atom({})
// Getter: Returns an array of cueIds (sorted)
export const flatCueIdsAtom = atom(
  (get) => {
    const rundown = get(rundownAtom)
    return rundown?.cues.map((cue) =>
      cue?.children?.length
        ? [cue.id, ...cue.children.map((child) => child.id)]
        : [cue.id],
    ).flat()
  },
  null,
)
// Getter: Returns an array of cues (sorted)
export const filteredAndSortedCuesAtom = atom(
  (get) => {
    const flatCueIds = get(flatCueIdsAtom)
    const cues = get(_cuesAtom)
    return Object.values(cues)
      .filter((cue) => flatCueIds?.includes(cue.id))
      .sort((a, b) => flatCueIds?.indexOf(a.id) - flatCueIds?.indexOf(b.id))
  },
  null,
)

export const firstCueIdAtom = atom(
  (get) => {
    const sortedCues = get(filteredAndSortedCuesAtom)
    for (const cue of sortedCues) {
      if (cue.type === CUE_TYPE_CUE) return cue.id
    }
    return null
  },
)

export const getPreviousCueAtom = atom(
  (get) => {
    const sortedCues = get(filteredAndSortedCuesAtom)
    return (cueId) => {
      // Find received id index
      const idx = sortedCues.findIndex((c) => c.id === cueId)

      // If not found or first
      if (idx === -1 || idx === 0) {
        return null
      }

      // Iterate backwards to find previous with type CUE
      for (let i = idx - 1; i >= 0; i--) {
        if (sortedCues[i].type === CUE_TYPE_CUE) {
          return sortedCues[i]
        }
      }

      // If not found, return null
      return null
    }
  },
)

// Getter: Returns a map of cues (unsorted)
// Setter: Accepts an array (!) of cues (unsorted)
export const cuesAtom = atom(
  (get) => {
    const filteredAndSortedCues = get(filteredAndSortedCuesAtom)
    return _keyBy(filteredAndSortedCues, 'id')
  },
  (get, set, updatedCues) => {
    set(_cuesAtom, _keyBy(updatedCues, 'id'))
  },
)

// rundown.cues:
// [
//   { id: string },
//   { id: string },
//   { id: string },
//   {
//     id: string,
//     children: [
//       { id: string },
//       { id: string },
//       { id: string },
//     ]
//   }
// ]

export const setCueAtom = atom(
  null, // write-only atom
  (get, set, cue) => {
    const cues = get(_cuesAtom)
    cues[cue.id] = cue
    set(_cuesAtom, cues)
  },
)
export const setCuesAtom = atom(
  null, // write-only atom
  (get, set, cuesArray) => {
    const cues = get(_cuesAtom)
    cuesArray.map((cue) => cues[cue.id] = cue)
    set(_cuesAtom, cues)
  },
)
export const setCueOrderAtom = atom(
  null, // write-only atom
  (get, set, cueIds) => {
    const rundown = get(rundownAtom)
    set(rundownAtom, { ...rundown, cues: cueIds })
  },
)

/**
 * Keep track of loaded cues. Only causes updates if loadedCue array actually changes.
 *
 * Note: Use spread operator, otherwise useEffect doesn't react to array changes.
 */
export const loadedCuesAtom = atom([])
export const maxLoadedCuesAtom = atom(20)
export const addLoadedCueAtom = atom(
  null, // write-only atom
  (get, set, cueId) => {
    const loadedCues = get(loadedCuesAtom)
    if (loadedCues.includes(cueId)) return
    const limit = get(maxLoadedCuesAtom)
    const start = Math.max(loadedCues.length - limit + 1, 0)
    const newLoadedCues = loadedCues.slice(start).concat(cueId)
    set(loadedCuesAtom, newLoadedCues)
  },
)
export const removeLoadedCueAtom = atom(
  null, // write-only atom
  (get, set, cueId) => {
    const loadedCues = get(loadedCuesAtom)
    if (!loadedCues.includes(cueId)) return
    set(loadedCuesAtom, [...loadedCues.filter((id) => id !== cueId)])
  },
)

// Toggled groups atom
export const collapsedGroupsAtom = atom([])
export const toggleCollapsedGroupsAtom = atom(
  null, // write-only atom
  (get, set, toggleStatus) => {
    const filteredAndSortedCues = get(filteredAndSortedCuesAtom)
    if (toggleStatus) {
      set(collapsedGroupsAtom, filteredAndSortedCues.filter((c) => c.type === 'group').map((c) => c.id))
    } else {
      set(collapsedGroupsAtom, [])
    }
  },
)

// AutoScroll functionality
const internalAutoScrollAtom = atomWithStorage('autoScroll', {})

export const setAutoScrollAtom = atom(
  null, // write-only atom
  (get, set, rundownId, newValue) => {
    const current = get(internalAutoScrollAtom)
    const updated = { ...current, [rundownId]: newValue }
    set(internalAutoScrollAtom, updated)
  },
)

export const getAutoScrollAtom = atom(
  (get) => {
    return get(internalAutoScrollAtom)
  },
)

// Selected cues / Batch operations
export const selectedCueIdsAtom = atom([])

export const toggleSelectedCuesAtom = atom(
  null, // write-only atom
  (get, set, id) => {
    const selectedIds = get(selectedCueIdsAtom)
    if (selectedIds.includes(id)) {
      set(selectedCueIdsAtom, selectedIds.filter((selectedId) => selectedId !== id))
    } else {
      set(selectedCueIdsAtom, [...selectedIds, id])
    }
  },
)

export const isCueSelectedAtom = atom((get) => (cueId) => {
  const selectedIds = get(selectedCueIdsAtom)
  return selectedIds.includes(cueId)
})
