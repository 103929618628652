import _trimEnd from 'lodash/trimEnd'
import { createEventSignature, createRundownSignature } from '../firestore.js'

/**
 * Generates a signed URL for accessing a specific rundown resource.
 * This function constructs a URL based on the given parameters and appends a signature to it
 * for secure access. It's primarily used for creating URLs that are meant to be shared or used
 * externally, ensuring that only those with the correct signature can access the resource.
 *
 * @param {string} rundownId
 * @param {string | string[]} path - The path or an array of path parts to be appended to the base URL,
 * leading to a specific resource within a rundown.
 * @param {Object} params - An object containing query parameters to be included in the URL.
 * Each key-value pair in the object will be added as a query parameter to the constructed URL.
 * @returns {Promise<string>} A promise that resolves to the fully constructed and signed URL as a string.
 * If an error occurs during the signature creation, the promise will resolve to `undefined`,
 * as errors are handled within the `createRundownSignature` function.
 */
export async function generateRundownLinkWithSignature (rundownId, path, params) {
  const pathParts = Array.isArray(path) ? path : [path]
  const iUrl = new URL(_joinUrl(window.location.origin, 'rundown', rundownId, ...pathParts))
  for (const key in params) {
    iUrl.searchParams.set(key, params[key])
  }
  try {
    const { data } = await createRundownSignature(rundownId, iUrl.href)
    iUrl.searchParams.set('signature', data.signature)
    return iUrl.href
  } catch {
    // Error already handled in createRundownSignature
  }
}

/**
 * Generates a signed URL for accessing a specific event resource.
 * This function constructs a URL based on the given parameters and appends a signature to it
 * for secure access. It's primarily used for creating URLs that are meant to be shared or used
 * externally, ensuring that only those with the correct signature can access the resource.
 *
 * @param {string} eventId
 * @param {boolean} fullAccess
 * @returns {Promise<string>} A promise that resolves to the fully constructed and signed URL as a string.
 * If an error occurs during the signature creation, the promise will resolve to `undefined`,
 * as errors are handled within the `createEventSignature` function.
 */
export async function generateEventLinkWithSignature (eventId, fullAccess) {
  const url = new URL(_joinUrl(window.location.origin, 'event', eventId))
  if (fullAccess) url.searchParams.set('full-access', '1')
  try {
    const { data } = await createEventSignature(eventId, url.href)
    url.searchParams.set('signature', data.signature)
    return url.href
  } catch {
    // Error already handled in createRundownSignature
  }
}

/**
 * Joins multiple URL parts into a single URL, ensuring there are no duplicate slashes
 * and that the URL ends without a trailing slash, unless it's the root slash.
 *
 * @param {...string} parts
 * @returns {string} The joined URL
 */
function _joinUrl(...parts) {
  return _trimEnd(parts.join('/').replace(/\/\//g, '/'), '/')
}
