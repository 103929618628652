import { useEffect } from 'react'
import Layout from '../components/Layout'
import { useSearchParams, useNavigate } from 'react-router-dom'
import * as PaddleService from '../utils/PaddleService.js'
import * as GtagService from '../utils/GtagService.js'
import { getUser } from '../firebase'

export default function Checkout() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    onMounted()
  }, [])

  async function onMounted () {
    const Paddle = await PaddleService.init()

    const planId = searchParams.get('plan_id')
    const teamId = searchParams.get('team_id')
    const discountCode = searchParams.get('discount_code')
    const discountId = searchParams.get('discount_id')
    const additionalMembersId = searchParams.get('additional_members_id')
    const additionalMembersCount = searchParams.get('additional_members_count')
    const transactionId = searchParams.get('_ptxn')

    PaddleService.on('checkout.loaded', (name, data) => {
      GtagService.beginCheckout({
        value: data.totals.subtotal,
        currency: data.currency_code,
      })
    })

    PaddleService.on('checkout.closed', () => {
      navigate('/dashboard')
    })

    PaddleService.on('checkout.completed', (name, data) => {
      GtagService.purchase({
        value: data.totals.subtotal,
        currency: data.currency_code,
        transactionId: data.transaction_id,
      })
      Paddle.Checkout.close()
      const query = new URLSearchParams()
      if (planId) query.set('plan_id', planId)
      if (teamId) query.set('team_id', teamId)
      navigate(`/checkout-success?${query}`)
    })

    // Open regular checkout
    if (planId && teamId) initCheckout({ planId, teamId, discountCode, discountId, additionalMembersId, additionalMembersCount })

    // Load a specific transaction
    if (transactionId) initTransactionCheckout({ transactionId })
  }

  async function initCheckout ({ planId, teamId, discountCode, discountId, additionalMembersId, additionalMembersCount } = {}) {
    const user = await getUser()

    let items = [
      {
        quantity: 1,
        price_id: planId,
      },
    ]

    if (additionalMembersId) {
      items.push(
        {
          quantity: parseInt(additionalMembersCount),
          price_id: additionalMembersId,
        },
      )
    }

    const checkoutConfig = {
      items,
      customData: {
        uid: user.uid,
        teamId: teamId,
      },
      customer: {
        email: user.email,
      },
    }
    if (discountCode) checkoutConfig.discountCode = discountCode
    else if (discountId) checkoutConfig.discountId = discountId

    // Trigger Paddle checkout
    const Paddle = await PaddleService.get()
    Paddle.Checkout.open(checkoutConfig)
  }

  // Load a specific transaction
  async function initTransactionCheckout ({ transactionId } = {}) {
    const Paddle = await PaddleService.get()
    return Paddle.Checkout.open({ transactionId })
  }

  return (
    <Layout title="Checkout">
    </Layout>
  )
}
