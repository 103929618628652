import Layout from '../components/Layout'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'

export default function CheckoutSuccess() {
  const navigate = useNavigate()

  return (
    <Layout title="Success 🎉">
      <p>You&apos;re all set and we&apos;ve just emailed you your receipt. </p>
      <Button text="To the dashboard" onClick={() => navigate('/dashboard')} />
    </Layout>
  )
}
