import QuickAccessCodeModal from '../QuickAccessCodeModal.jsx'
import QrCodeModal from '../QrCodeModal.jsx'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import copy from 'copy-to-clipboard'

export default function ShareableLinkAndAccessCode ({ url = '', loading = false, className = '', handleRevoke, ...props }) {
  const [linkCopied, setLinkCopied] = useState(false)
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false)
  const [quickAccessModalOpen, setQuickAccessModalOpen] = useState(false)

  function onCopy() {
    copy(url)
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 1500)
  }

  return (
    <div
      className={['space-y-3', className].join(' ')}
      {...props}
    >
      {/* Link */}
      <div className="flex gap-2">
        <div
          className={[
            'flex-grow rounded h-9 text-xs',
            (loading ? 'bg-animated from-gray-800 to-gray-600 text-white/70' : 'bg-gray-800'),
          ].join(' ')}
        >
          <input
            key={url} // needed for repaint when switching back from the PDF tab
            defaultValue={url}
            type="text"
            onClick={(event) => event.target.select()}
            readOnly
            disabled={loading}
            className="bg-transparent h-9 w-full px-3 focus:outline-none focus:ring rounded"
          />
        </div>
        <button
          className={[
            'rounded text-sm h-9 leading-9 px-3 focus:outline-none focus:ring ',
            (loading ? 'bg-animated from-gray-800 to-gray-600 text-white/70' : 'bg-gray-800 hover:bg-gray-700'),
          ].join(' ')}
          onClick={onCopy}
        >
          <FontAwesomeIcon icon={linkCopied ? faCheck : faCopy} size="sm" className="mr-2" />
          <span>{linkCopied ? 'Copied' : 'Copy'}</span>
        </button>
      </div>
      <div className="flex gap-4">
        { handleRevoke && (
          <button
            className="text-xs font-semibold text-gray-500 hover:text-gray-300 focus:outline-none focus:ring"
            onClick={handleRevoke}
          >
            Expire previous links
          </button>
        ) }
        <div className="grow"></div>
        <button
          className="text-xs font-semibold text-gray-500 hover:text-gray-300 focus:outline-none focus:ring"
          onClick={() => setQuickAccessModalOpen(true)}
        >
          Quick Access Code
        </button>
        <button
          className="text-xs font-semibold text-gray-500 hover:text-gray-300 focus:outline-none focus:ring"
          onClick={() => setQrCodeModalOpen(true)}
        >
          QR Code
        </button>
        <a
          className="text-xs font-semibold text-gray-500 hover:text-gray-300 focus:outline-none focus:ring"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <span>Open</span>
          <FontAwesomeIcon icon={faUpRightFromSquare} size="sm" className="ml-2" />
        </a>
      </div>

      {/* Quick Access Code */}
      <QuickAccessCodeModal
        open={quickAccessModalOpen}
        setOpen={setQuickAccessModalOpen}
        url={url}
      />

      {/* QR Code */}
      <QrCodeModal
        open={qrCodeModalOpen}
        setOpen={setQrCodeModalOpen}
        url={url}
      />
    </div>
  )
}

ShareableLinkAndAccessCode.propTypes = {
  loading: PropTypes.bool,
  url: PropTypes.string,
  className: PropTypes.string,
  handleRevoke: PropTypes.func,
}
