import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import * as OUTPUT_TYPES from '../../../constants/outputConfigTypes'
import CurrentTimeOfDay from '../CurrentTimeOfDay'
import _clamp from 'lodash/clamp'
import _round from 'lodash/round'
import RundownTextFitWrapper from './RundownTextFitWrapper'
import { addMilliseconds } from 'date-fns'
import { formatDurationHuman } from '../../../utils/formatTime'

const TickState = {
  FUTURE: 'future',
  NOW: 'now',
  PAST: 'past',
  OVERTIME: 'overtime',
}

export default function RundownOutputHeader ({ rundown, timestamps, moment, event }) {
  const [searchParams] = useSearchParams()

  const percent = 1 - (moment?.left) / (moment?.total || 1)
  const progressPct = _clamp(_round(percent * 100, 2), 0, 100)
  const overtime = moment?.left <= 0

  const getConfigValue = (param) => searchParams.get(param)

  const configHeaderPosition = getConfigValue(OUTPUT_TYPES.HEADER_POSITION) || OUTPUT_TYPES.TOP
  const configRundownTitle = getConfigValue(OUTPUT_TYPES.RUNDOWN_TITLE) || OUTPUT_TYPES.ON
  const configProgressBar = getConfigValue(OUTPUT_TYPES.PROGRESS_BAR) || OUTPUT_TYPES.ON
  const configLogo = getConfigValue(OUTPUT_TYPES.LOGO) || OUTPUT_TYPES.ON
  const configTimeOfDay = getConfigValue(OUTPUT_TYPES.TIME_OF_DAY) || OUTPUT_TYPES.ON
  const configOverUnder = getConfigValue(OUTPUT_TYPES.OVER_UNDER) || OUTPUT_TYPES.ON
  const configLastFiveSeconds = getConfigValue(OUTPUT_TYPES.LAST_FIVE_SECONDS) || OUTPUT_TYPES.ON

  if (!timestamps) return null

  const actualEnd = addMilliseconds(timestamps.actual.start, timestamps.actual.duration)
  const originalEnd = addMilliseconds(timestamps.original.start, timestamps.original.duration)

  const diff = originalEnd - actualEnd

  return (
    <div
      className={[
        'flex',
        configHeaderPosition === OUTPUT_TYPES.TOP ? 'flex-col' : 'flex-col-reverse',
      ].join(' ')}
      style={{ height: '12vh' }}
    >
      <div className="flex justify-end items-center gap-8 p-3 pb-0">

        {/* Rundown title */}
        {configRundownTitle === OUTPUT_TYPES.OFF
          ? ''
          : (
              <RundownTextFitWrapper height="3vh" className="font-semibold">
                <div className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {rundown?.name}
                </div>
              </RundownTextFitWrapper>
            )}

        <span className="flex-1"></span>

        {/* Logo */}
        {configLogo === OUTPUT_TYPES.OFF
          ? ''
          : (
              <div
                className="text-center"
                style={{ height: 'auto', width: 'auto' }}
              >
                {configLogo?.includes('http')
                  ? (
                      <img className="max-h-[3vh] w-max" src={configLogo} />
                    )
                  : (
                      <img className="max-h-[3vh] aspect-auto my-auto" src={rundown.logo || event?.logo || '/img/logo.svg'} />
                    )}
              </div>
            )}

        {/* Time of day */}
        {configTimeOfDay === OUTPUT_TYPES.OFF
          ? ''
          : (
              <RundownTextFitWrapper height="3vh">
                <div className="whitespace-nowrap font-mono">
                  <CurrentTimeOfDay
                    timezone={rundown?.timezone}
                    showTimezone
                  />
                </div>
              </RundownTextFitWrapper>
            )}

        {/* Over/Under */}
        {configOverUnder === OUTPUT_TYPES.OFF
          ? ''
          : (
              <RundownTextFitWrapper height="3vh">
                <div className="font-mono">
                  <span className="text-sm leading-6">
                    {diff >= 1000
                      ? (
                          <p className="text-green-500">
                            <span className="font-mono">{formatDurationHuman(diff)}</span>
                            {' '}
                            early
                          </p>
                        )
                      : ''}
                    {diff < 1000 && diff > -1000 ? '--' : ''}
                    {diff <= -1000
                      ? (
                          <p className="text-red-500">
                            <span className="font-mono">{formatDurationHuman(diff)}</span>
                            {' '}
                            late
                          </p>
                        )
                      : ''}
                  </span>
                </div>
              </RundownTextFitWrapper>
            )}
      </div>

      <div className="px-3 flex justify-end gap-4">
        {/* Progress bar */}
        {configProgressBar === OUTPUT_TYPES.OFF ? ''
          : (
              <div className="grow h-[4vh] flex items-center">
                <div className="flex w-full h-100 bg-gray-900 rounded-[0.4vh]">
                  {/* PROGRESS */}
                  <div className={[
                    'relative flex-grow h-[2vh]',
                  ].join(' ')}
                  >
                    {/* PROGRESS BACKGROUND */}
                    <div className="w-full h-full rounded-[0.4vh] bg-gradient-to-r from-blue-500 to-red-600"></div>

                    {/* PROGRESS DARK OVERLAY */}
                    <div
                      style={{ width: `${progressPct}%` }}
                      className={[
                        'absolute top-0 left-0 bottom-0 rounded-[0.4vh] bg-black/70 transition-all',
                        (progressPct < 100 ? ' !rounded-r-none' : ''),
                      ].join(' ')}
                    >
                    </div>

                  </div>
                </div>

              </div>
            )}
        {/* Last five seconds */}
        {configLastFiveSeconds === OUTPUT_TYPES.OFF
          ? ''
          : (
              <div className="h-[4vh] flex items-center gap-2">
                {[5000, 4000, 3000, 2000, 1000].map((ms, i) => {
                  let state = TickState.FUTURE
                  if (overtime) state = TickState.OVERTIME
                  else if (moment?.left <= ms - 1000) state = TickState.PAST
                  else if (moment?.left <= ms) state = TickState.NOW
                  const flash = overtime && Math.ceil(moment?.left / 1000) % 2 === 0
                  return (
                    <div
                      key={'5-sec-' + i}
                      className={[
                        'pointer-events-none h-[2.4vh]',
                        'aspect-square rounded-[0.7vh] border-[0.1vh] transition-all hover:border-white',
                        state === TickState.FUTURE ? 'border-gray-900 bg-red-600' : '',
                        state === TickState.NOW ? 'border-white bg-red-600' : '',
                        state === TickState.PAST ? 'border-gray-900 bg-red-600/30' : '',
                        state === TickState.OVERTIME && !flash ? 'border-gray-900 bg-red-600/30' : '',
                        state === TickState.OVERTIME && flash ? 'border-white bg-red-600' : '',
                        state,
                      ].join(' ')}
                      style={flash ? { boxShadow: '#b91c1b 0 0 8px' } : null}
                    >
                    </div>
                  )
                })}
              </div>
            )}
      </div>
    </div>
  )
}

RundownOutputHeader.propTypes = {
  rundown: PropTypes.object,
  timestamps: PropTypes.object,
  moment: PropTypes.object,
  runner: PropTypes.object,
  columnId: PropTypes.string,
  columns: PropTypes.array,
  event: PropTypes.object,
}
