import localStorage from './localStorage.js'
import _clamp from 'lodash/clamp'

const LOG_LEVEL_KEY = 'rundown-studio:loglevel'
const LOG_LEVELS = {
  NONE: 0,
  ERROR: 1,
  INFO: 2,
  ALL: 3,
}

function getLogLevel () {
  const level = parseInt(localStorage.getItem(LOG_LEVEL_KEY)) || LOG_LEVELS.NONE
  return _clamp(level, LOG_LEVELS.NONE, LOG_LEVELS.ALL)
}

export function setLogLevel (level) {
  localStorage.setItem(LOG_LEVEL_KEY, level)
}

function shouldLog (minimumLevel) {
  return getLogLevel() >= minimumLevel
}

export function log (...args) {
  if (shouldLog(LOG_LEVELS.ALL)) console.log(...args)
}

export function info (...args) {
  if (shouldLog(LOG_LEVELS.INFO)) console.info(...args)
}

export function warn (...args) {
  if (shouldLog(LOG_LEVELS.INFO)) console.warn(...args)
}

export function error (...args) {
  if (shouldLog(LOG_LEVELS.ERROR)) console.error(...args)
}

export function debug (...args) {
  if (shouldLog(LOG_LEVELS.ALL)) console.debug(...args)
}

export const LogLevels = LOG_LEVELS
