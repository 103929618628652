import { initializePaddle } from '@paddle/paddle-js'

// Store the callbacks for Paddle events.
const callbacks = {}

// Keep track of the Paddle instance.
let paddleInstancePromise = null

/**
 * Initializes the Paddle instance with the given environment and token.
 * This function is idempotent, meaning that calling it multiple times will not result in multiple initializations.
 *
 * @idempotent
 * @returns {Promise<Paddle>} A promise that resolves to the Paddle instance.
 */
export async function init () {
  // Return the existing promise if Paddle is already being or has been initialized.
  if (paddleInstancePromise) return paddleInstancePromise

  // Define the environment and token from the import.meta.env variables.
  const environment = import.meta.env.VITE_PADDLE_SANDBOX === 'ON' ? 'sandbox' : 'production'
  const token = import.meta.env.VITE_PADDLE_TOKEN

  // Define the event callback function.
  const eventCallback = ({ name, data }) => {
    if (callbacks[name]) {
      callbacks[name](name, data)
    }
  }

  // Create a promise to initialize Paddle, store it, and return it.
  paddleInstancePromise = initializePaddle({
    environment,
    token,
    eventCallback,
    pwCustomer: null,
  })
  return paddleInstancePromise
}

/**
 * Inititalize Paddle with the customerId if available.
 * Used for Paddle Retain and ProfitWell.
 * @param  {string} customerId - Paddle customerId starting with 'ctm_'
 * @return {void}
 */
export async function setCustomer (customerId) {
  if (!customerId) return
  const paddle = await init()
  paddle.Update({
    pwCustomer: {
      id: customerId,
    },
  })
  console.info(`[Paddle] init customer: ${customerId.substring(0, 15)}***`)
}

/**
 * Retrieves the instantiated Paddle instance.
 * This function is idempotent.
 *
 * @returns {Promise<Paddle>} A promise that resolves to the Paddle instance.
 */
export async function get () {
  // Ensure the Paddle instance is initialized before returning it.
  // If not initialized, init() will be called to create the instance.
  await init()
  return paddleInstancePromise
}

/**
 * Registers a callback function for a specific Paddle event.
 *
 * @param {string} event - The name of the Paddle event.
 * @param {function} callback - The callback function to execute when the event is triggered.
 */
export function on (event, callback) {
  callbacks[event] = callback
}
