import PropTypes from 'prop-types'

export default function UserIcon({ user, size = 'w-4 h-4' }) {
  function userImg() {
    return user?.photoURL || '/img/default-avatar.svg'
  }

  return (
    <div
      className={`${size} rounded-full border border-indigo-100 bg-indigo-800`}
      style={{
        backgroundImage: `url(${userImg()})`,
        backgroundSize: 'cover',
      }}
    >
    </div>
  )
}

UserIcon.propTypes = {
  user: PropTypes.object,
  size: PropTypes.string,
}
