import { filteredAndSortedCuesAtom, columnsAtom } from '../../store/rundown.store.js'
import { useAtomValue } from 'jotai'
import { useEffect, useCallback } from 'react'
import eventBus from '../../utils/eventBus.js'
import { CUE_TYPE_CUE } from '../../constants/cueTypes.js'
import _indexOf from 'lodash/indexOf'

/**
 * Handles keyboard navigation for cells in a rundown.
 * It listens for keyboard navigation events and calculates the target cell based on the current cell's position and the navigation direction.
 * The component uses atoms to access the sorted cues and columns for navigation.
 */
export default function KeyboardNavigationHandler () {
  const sortedCues = useAtomValue(filteredAndSortedCuesAtom)
  const sortedColumns = useAtomValue(columnsAtom)

  const cueIds = sortedCues.filter((cue) => cue.type === CUE_TYPE_CUE).map((cue) => cue.id)
  const colIds = sortedColumns.map((col) => col.id)

  /**
   * Handles cell keyboard navigation events.
   * Calculates the target cell based on the navigation direction and emits an event to focus the target cell.
   *
   * @param {string} params.cueId - The ID of the current cue.
   * @param {string} params.colId - The ID of the current column.
   * @param {string} params.direction - The navigation direction ('up', 'down', 'left', 'right').
   * @param {TipTapEvent} params.event - The TipTap wrapper around the original keyboard event.
   */
  function handleEvent ({ cueId, colId, direction, event }) {
    // Determine source and target coordinates based on the navigation direction
    const sourceXY = [_indexOf(cueIds, cueId), _indexOf(colIds, colId)]
    const targetXY = sourceXY.slice()
    switch (direction) {
      case 'up':
        targetXY[0]--
        break
      case 'down':
        targetXY[0]++
        break
      case 'left':
        targetXY[1]--
        break
      case 'right':
        targetXY[1]++
        break
    }

    // Check if the target coordinates are valid
    const targetCueId = cueIds[targetXY[0]]
    const targetColId = colIds[targetXY[1]]
    if (!targetCueId || !targetColId) return

    // Determine focus location based on navigation direction
    const location = ['right', 'down'].includes(direction) ? 'start' : 'end'

    // Emit an event to the cell at the target coordinates
    // and prevent the event default since we'll trigger our own action
    event.preventDefault()
    eventBus.$emit(`focusCell_cue:${targetCueId}_col:${targetColId}`, { location })
  }

  const stableHandleEvent = useCallback(handleEvent, [cueIds, colIds])

  // Listen for cell keyboard navigation events and handle them
  useEffect(() => {
    eventBus.$on('cellKeyboardNavigation', stableHandleEvent)
    return () => eventBus.$off('cellKeyboardNavigation', stableHandleEvent)
  }, [stableHandleEvent])

  // Renderless component
  return null
}
