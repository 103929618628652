import { faArrowRightLong, faCog, faCopy, faPlus, faTrashAlt, faGripLines, faPalette, faForwardStep, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons'
import { CueType } from '@rundown-studio/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import ContextMenu from '../ContextMenu'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../constants/cueTypes.js'
import ModalWrapper from '../modal/ModalWrapper.jsx'
import { Tooltip, TooltipContent, TooltipTrigger } from '../interactives/Tooltip.jsx'
import { filteredAndSortedCuesAtom, isCueSelectedAtom, rundownAtom, selectedCueIdsAtom, toggleSelectedCuesAtom } from '../../store/rundown.store.js'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

function CueItemSettings ({
  cue,
  readonly,
  onUpdateCue,
  onDeleteCue,
  onAddCue,
  onDuplicateCue,
  onJumpToCue,
  onSetNext,
  parentId,
  setRundownColoursModalOpen,
  cueBackgroundColours,
}) {
  const [showContext, setShowContext] = useState(null)
  const [deletingGroup, setDeletingGroup] = useState(false)
  const filteredAndSortedCues = useAtomValue(filteredAndSortedCuesAtom)
  const rundown = useAtomValue(rundownAtom)
  const getIsCueSelected = useAtomValue(isCueSelectedAtom)
  const [selectedCues, setSelectedCues] = useAtom(selectedCueIdsAtom)
  const toggleSelectedCue = useSetAtom(toggleSelectedCuesAtom)
  const isCueSelected = getIsCueSelected(cue.id)
  const isParentCueSelected = getIsCueSelected(parentId)

  const handleItemClick = async (item, secondary) => {
    setShowContext(false)
    switch (item) {
      case 'colour_picker':
        return onUpdateCue({ backgroundColor: secondary })
      case 'edit_rundown_colors':
        return setRundownColoursModalOpen(true)
      case 'jump_to_cue':
        return onJumpToCue()
      case 'cue_up_next':
        return onSetNext()
      case 'change_type':
        return onUpdateCue({ type: secondary })
      case 'add_cue':
        return onAddCue({ position: secondary })
      case 'duplicate':
        return onDuplicateCue()
      case 'lock':
        return onUpdateCue({ locked: !cue.locked })
      case 'delete':
        return onDeleteCue()
      case 'delete-group':
        return setDeletingGroup(true)
      default:
        return console.info(item, secondary)
    }
  }

  /**
   * Handles the selection of cues in the rundown.
   *
   * - If the shift key is held during selection, selects a range of cues from the
   *   last selected cue to the current one. If any group cues are selected in this
   *   range, their child cues are removed from the selection.
   * - If selecting a group cue directly, removes any selected children cues of that
   *   group and selects the group cue itself.
   * - Otherwise, toggles the selected state of the individual cue.
   *
   * @param {Object} event - The selection event.
   */
  function onSelectCue(event) {
    // Check if shift key is held to perform range selection
    if (event.nativeEvent.shiftKey) {
      const sortedCueIds = filteredAndSortedCues.map((c) => c.id)
      const lastSelectedIndex = sortedCueIds.indexOf(selectedCues[selectedCues.length - 1])
      const currentIndex = sortedCueIds.indexOf(cue.id)

      // Determine the start and end indices for the range selection
      const [start, end] = [lastSelectedIndex, currentIndex].sort((a, b) => a - b)
      const newSelectedIds = sortedCueIds.slice(start, end + 1)

      // Combine current selections with the new range, removing duplicates
      const updatedSelectedCues = Array.from(new Set([...selectedCues, ...newSelectedIds]))

      // If any group cues are selected, remove their child cues
      const groupHeaders = rundown.cues.filter((c) => c.children && updatedSelectedCues.includes(c.id))
      const selectedChildren = groupHeaders.flatMap((g) => g.children.map((c) => c.id))
      const selectedWithoutChildren = updatedSelectedCues.filter((id) => !selectedChildren.includes(id))

      setSelectedCues(selectedWithoutChildren)
    } else {
      // Handle individual cue selection or group cue selection
      if (event.target.checked && cue.type === CueType.GROUP) {
        // Remove any selected children cues of this group and select the group cue
        const childrenCues = rundown.cues.find((c) => c.id === cue.id).children?.map((c) => c.id)
        const selectedWithoutChildren = selectedCues.filter((id) => !childrenCues.includes(id))
        setSelectedCues([...selectedWithoutChildren, cue.id])
      } else {
        // Toggle the selected state of the individual cue
        toggleSelectedCue(cue.id)
      }
    }
  }

  function onGearBtnClick (event) {
    setShowContext(event.nativeEvent)
  }

  function onLockBtnClick () {
    handleItemClick('lock')
  }

  if (readonly) return null
  return (
    <div className={[
      'flex flex-col h-full group-hover:backdrop-blur min-h-14 justify-between rounded',
      isParentCueSelected || isCueSelected ? 'bg-blue-700/90' : '',
    ].join(' ')}
    >
      {/* Drag Handle */}
      {cue.locked || selectedCues.length > 0
        ? <span className="h-6"></span>
        : (
            <button
              className={[
                'flex-none w-full opacity-0 group-hover:opacity-70 hover:!opacity-100 cursor-grab rounded focus:outline-none focus:ring',
              ].join(' ')}
              data-draghandle
            >
              <FontAwesomeIcon icon={faGripLines} />
            </button>
          )}
      {/* Gear Button */}
      <button
        className={[
          'flex-none w-full h-7 rounded  enabled:group-hover:opacity-70 enabled:hover:!opacity-100 transition-opacity focus:outline-none focus:ring disabled:hidden',
          parentId ? 'group-hover:opacity-30 opacity-0' : 'opacity-30',
          isCueSelected ? 'opacity-30' : '',
        ].join(' ')}
        disabled={selectedCues.length > 0 || isParentCueSelected}
        onClick={onGearBtnClick}
      >
        <FontAwesomeIcon icon={faCog} size="sm" />
      </button>
      {/* Lock Button OR Collapse button */}
      {
        cue.locked
          ? (
              <Tooltip>
                <TooltipTrigger asChild={true}>
                  <button
                    className="flex-none w-full h-7 rounded group-hover:opacity-70 hover:!opacity-100 transition-opacity backdrop-blur text-xs focus:outline-none focus:ring group/lock opacity-70 text-yellow-500"
                    onClick={onLockBtnClick}
                  >
                    <span className="hidden group-hover/lock:inline">
                      <FontAwesomeIcon icon={faUnlock} size="sm" />
                    </span>
                    <span className="group-hover/lock:hidden">
                      <FontAwesomeIcon icon={faLock} size="sm" />
                    </span>
                  </button>
                </TooltipTrigger>
                <TooltipContent>Allow cue editing</TooltipContent>
              </Tooltip>
            )
          : !isParentCueSelected && (
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className="flex flex-col justify-center align-middle w-full h-7 rounded"
                  >
                    <label htmlFor={`select-${cue.id}`} className="mx-auto p-1.5">
                      <input
                        type="checkbox"
                        checked={isCueSelected}
                        onChange={onSelectCue}
                        id={`select-${cue.id}`}
                        className={[
                          'mx-auto group-hover:opacity-70 hover:!opacity-100 transition-opacity text-xs focus:outline-none focus:ring',
                          isCueSelected ? 'opacity-70' : 'opacity-0',
                        ].join(' ')}
                      />
                    </label>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {isCueSelected ? 'Deselect' : 'Select'}
                  {' '}
                  {cue.type === CueType.GROUP ? 'group' : 'cue'}
                </TooltipContent>
              </Tooltip>
            )
      }
      {showContext && (
        <ContextMenu
          openEvent={showContext}
          onClose={() => setShowContext(null)}
          onClick={handleItemClick}
          items={[
            {
              name: 'Colours',
              value: 'colour_picker',
              type: 'colour',
              options: cueBackgroundColours,
            },
            {
              name: 'Edit rundown colors',
              value: 'edit_rundown_colors',
              icon: faPalette,
            },
            {
              type: 'spacer',
            },
            {
              name: 'Jump to this cue',
              value: 'jump_to_cue',
              icon: faForwardStep,
              disabled: [CUE_TYPE_HEADING, CUE_TYPE_GROUP].includes(cue.type),
            },
            {
              name: 'Cue up next',
              value: 'cue_up_next',
              icon: faArrowRightLong,
              disabled: [CUE_TYPE_HEADING, CUE_TYPE_GROUP].includes(cue.type),
            },
            {
              name: `Duplicate ${cue.type === CueType.GROUP ? 'group' : 'cue'}`,
              value: 'duplicate',
              icon: faCopy,
            },
            {
              name: 'Add cue...',
              value: 'add_cue',
              type: 'secondary',
              options: [
                {
                  name: 'Add cue above',
                  value: 'before',
                  icon: faPlus,
                },
                {
                  name: 'Add cue below',
                  value: 'after',
                  icon: faPlus,
                },
              ],
            },
            {
              name: 'Convert to...',
              value: 'change_type',
              type: 'secondary',
              options: [
                {
                  name: 'Cue',
                  value: CUE_TYPE_CUE,
                },
                {
                  name: 'Heading',
                  value: CUE_TYPE_HEADING,
                },
              ],
              disabled: cue.type === CUE_TYPE_GROUP,
            },
            {
              name: cue.locked ? 'Allow edits' : 'Prevent edits',
              value: 'lock',
              icon: cue.locked ? faUnlock : faLock,
            },
            {
              type: 'spacer',
            },
            {
              name: cue.type === CUE_TYPE_GROUP ? 'Delete group' : 'Delete cue',
              value: cue.type === CUE_TYPE_GROUP ? 'delete-group' : 'delete',
              icon: faTrashAlt,
              disabled: cue.locked,
            },
          ]}
        />
      )}

      {deletingGroup
      && (
        <ModalWrapper
          open={[deletingGroup, setDeletingGroup]}
          title="Delete Group"
          buttons={[
            {
              text: 'Cancel',
              onClick: () => setDeletingGroup(false),
            },
            {
              text: 'Delete Group',
              icon: faTrashAlt,
              onClick: async () => {
                setDeletingGroup(false)
                onDeleteCue()
              },
            },
          ]}
        >
          <p>Deleting this group will permanently remove all cues within it.</p>
        </ModalWrapper>
      )}
    </div>
  )
}

CueItemSettings.propTypes = {
  cue: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  onUpdateCue: PropTypes.func.isRequired,
  onDeleteCue: PropTypes.func.isRequired,
  onAddCue: PropTypes.func.isRequired,
  onDuplicateCue: PropTypes.func.isRequired,
  onJumpToCue: PropTypes.func.isRequired,
  onSetNext: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  setRundownColoursModalOpen: PropTypes.func,
  cueBackgroundColours: PropTypes.array,
}

export default memo(CueItemSettings, (prevProps, nextProps) => {
  // Note: We don't need to compare stable callbacks
  // 1: Comparing array signature should be enough
  const propsEqual
    = prevProps.cue.id === nextProps.cue.id
    && prevProps.cue.type === nextProps.cue.type
    && prevProps.cue.locked === nextProps.cue.locked
    && prevProps.readonly === nextProps.readonly
    && prevProps.parentId === nextProps.parentId
    && prevProps.cueBackgroundColours === nextProps.cueBackgroundColours /* 1 */

  return propsEqual
})
