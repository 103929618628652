import PropTypes from 'prop-types'
import { EditorProvider } from '@tiptap/react'
import TipTapContentUpdater from './TipTapContentUpdater.jsx'
import TipTapPropsUpdater from './TipTapPropsUpdater.jsx'
import TipTapFocusHandler from './TipTapFocusHandler.jsx'
import TipTapMenuBar from './TipTapMenuBar.jsx'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from 'react'
import { IMAGE_TYPES, MAX_FILE_SIZE, MAX_FREE_FILE_SIZE } from '../../constants/fileTypes.js'
import { uploadRundownFile } from '../../firestore.js'
import FileNodeView from './extensions/FileNodeView.js'
import ImageNodeView from './extensions/ImageNodeView.js'
import { BASIC } from '../../constants/billingTypes.js'
import { useAtomValue, useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store.js'
import UpgradeModal from '../modal/UpgradeModal.jsx'
import { planAtom } from '../../store/plan.store.js'
import formatBytes from '../../utils/formatBytes.js'

/**
 * EditorProvider:
 * Source: https://github.com/ueberdosis/tiptap/blob/develop/packages/react/src/Context.tsx#L20
 * Props: https://github.com/ueberdosis/tiptap/blob/develop/packages/core/src/types.ts#L52
 */

const CURSOR_POS = {
  START: 'START',
  END: 'END',
  INSIDE: 'INSIDE',
  NONE: 'NONE',
}

export default function Tiptap ({
  content = '',
  onUpdateContent,
  rundownId,
  readonly = false,
  onUpdateFocus = () => {},
  eventEmitter,
  className = '',
  ...props
}) {
  const [uploading, setUploading] = useState(false)
  const [upgradeModal, setUpgradeModal] = useState(false)
  const [cursorPos, setCursorPos] = useState(CURSOR_POS.NONE)
  const [isEmpty, setIsEmpty] = useState(true)
  const plan = useAtomValue(planAtom)
  const addToast = useSetAtom(toastAtom)
  const tipTapElement = useRef()

  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit,
    Highlight.configure({
      multicolor: true,
      HTMLAttributes: {
        class: 'px-0.5 rounded-sm box-decoration-clone',
      },
    }),
    TaskItem,
    TaskList,
    Link,
    Placeholder.configure({
      placeholder: 'Type or drop a file...',
    }),
    FileNodeView,
    ImageNodeView,
  ]

  async function handleFileDrop (rundownId, view, files) {
    const pos = view.posAtCoords({ left: event.clientX, top: event.clientY }).pos

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (file.size > MAX_FILE_SIZE) return addToast({ title: `File exceeds maximum size (${formatBytes(MAX_FILE_SIZE)}).`, type: 'fail' })
      if (!plan.features.includes(BASIC) && file.size > MAX_FREE_FILE_SIZE) return setUpgradeModal(true)

      const { data } = await uploadRundownFile(rundownId, file)
      let newNode
      if (IMAGE_TYPES.includes(file.type)) {
        newNode = view.state.schema.nodes.imageComponent.create({ src: data.url })
      } else {
        newNode = view.state.schema.nodes.fileComponent.create({ filename: file.name, size: formatBytes(file.size), type: file.type, url: data.url })
      }
      const newTransaction = view.state.tr.insert(pos + index, newNode)
      view.dispatch(newTransaction)
    }
  }

  const editorProps = {
    attributes: { class: 'px-3 py-2 h-full focus:outline-none focus:ring rounded' },
    handleDrop: (view, event, slice, moved) => {
      if (!moved && event.dataTransfer?.files?.length) {
        setUploading(true)
        return handleFileDrop(rundownId, view, event.dataTransfer.files).then(() => setUploading(false))
      }
      return false // not handled use default behaviour
    },
  }

  /**
   * Handle keyDown event for specific navigational keys
   * Note: If `event.defaultPrevented` is true, then TipTap already handled the event, for example by indenting a list.
   * @param  {TipTapEvent} event
   */
  function onKeyDown (event) {
    if (!eventEmitter) return
    if (!event.target.isContentEditable || event.defaultPrevented) return
    // jump to previous focusable element
    if (event.key === 'Tab' && event.shiftKey) {
      return eventEmitter.emit('jump', { direction: 'left', event })
    }
    // jump to next focusable element
    if (event.key === 'Tab') {
      return eventEmitter.emit('jump', { direction: 'right', event })
    }
    // if cursor start, then jump to previous cell possible
    if (event.key === 'ArrowLeft' && cursorPos === CURSOR_POS.START) {
      return eventEmitter.emit('jump', { direction: 'left', event })
    }
    // if cursor end, then jump to next cell possible
    if (event.key === 'ArrowRight' && (cursorPos === CURSOR_POS.END || isEmpty)) {
      return eventEmitter.emit('jump', { direction: 'right', event })
    }
    // if cursor start, then jump to above cell if possible
    if (event.key === 'ArrowUp' && cursorPos === CURSOR_POS.START) {
      return eventEmitter.emit('jump', { direction: 'up', event })
    }
    // if cursor end, then jump to below cell if possible
    if (event.key === 'ArrowDown' && (cursorPos === CURSOR_POS.END || isEmpty)) {
      return eventEmitter.emit('jump', { direction: 'down', event })
    }
  }

  function onTransaction ({ editor, transaction }) {
    // Check if editor is empty (size 2 because empty <p></p>)
    setIsEmpty(transaction.doc.content.size <= 2)

    // Determine cursor position
    if (!editor.isFocused) return setCursorPos(CURSOR_POS.NONE)
    const cursorPos = transaction.curSelection.$head.pos
    const contentSize = transaction.doc.content.size
    if (cursorPos <= 1) return setCursorPos(CURSOR_POS.START)
    if (cursorPos >= contentSize - 1) return setCursorPos(CURSOR_POS.END)
    return setCursorPos(CURSOR_POS.INSIDE)
  }

  return (
    <div
      ref={tipTapElement}
      id="tiptap" // needed for keyboard shortcuts elsewhere
      className={[
        'tiptap-wrapper text-[13px] transition-colors',
        className,
      ].join(' ')}
      {...props}
      onKeyDown={onKeyDown}
    >
      <EditorProvider
        extensions={extensions}
        content={content}
        editorProps={editorProps}
        editable={!readonly}
        onUpdate={({ editor }) => onUpdateContent(editor.getHTML())}
        onFocus={() => onUpdateFocus(true)}
        onBlur={() => onUpdateFocus(false)}
        onTransaction={onTransaction}
        onCreate={() => eventEmitter.emit?.('onload')}
      >
        <TipTapContentUpdater content={content} />
        <TipTapPropsUpdater editable={!readonly} />
        <TipTapFocusHandler eventEmitter={eventEmitter} />
        {!readonly && <TipTapMenuBar />}
      </EditorProvider>
      {uploading && (
        <div className="absolute bottom-1 left-1 bg-black/40 rounded px-1 text-blue-500 text-xs">
          <FontAwesomeIcon icon={faCloudArrowUp} size="sm" />
          <span className="ml-1">Uploading</span>
        </div>
      )}
      <UpgradeModal setOpen={setUpgradeModal} open={upgradeModal} message="This file exceeds the maximum of 5 MB available on the free plan." />
    </div>
  )
}

Tiptap.propTypes = {
  content: PropTypes.string,
  onUpdateContent: PropTypes.func.isRequired, // Must return a promise
  rundownId: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  onUpdateFocus: PropTypes.func,
  eventEmitter: PropTypes.object,
  className: PropTypes.string,
}
