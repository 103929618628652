import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as GtagService from '../../utils/GtagService.js'

export default function PageViewTracker () {
  const location = useLocation()

  useEffect(() => {
    GtagService.pageview(location.pathname)
  }, [location])

  return null
}
