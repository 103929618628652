import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import Button from '../Button'
import { selectedCueIdsAtom } from '../../store/rundown.store'
import { useAtom } from 'jotai'
import { deleteRundownCues, duplicateRundownCues, groupRundownCues, moveRundownCues, updateRundownCues } from '../../firestore'
import { CUE_TYPE_GROUP } from '../../constants/cueTypes'
import { faArrowDown, faArrowUp, faClone, faFillDrip, faLayerGroup, faRemove, faSquareCheck, faTrash, faUpDown } from '@fortawesome/free-solid-svg-icons'
import { Popover, PopoverContent, PopoverTrigger } from '../interactives/Popover'
import CueColorPicker from './CueColorPicker'
import ModalWrapper from '../modal/ModalWrapper.jsx'
import { Tooltip, TooltipContent, TooltipTrigger } from '../interactives/Tooltip.jsx'
import { captureException } from '../../sentry.js'

function BatchEditToolbar ({ rundownId, cues, cueOrder, setRundownColoursModalOpen }) {
  const [selectedCues, setSelectedCues] = useAtom(selectedCueIdsAtom)
  const [loadingDuplicate, setLoadingDuplicate] = useState(false)
  const [moveInput, setMoveInput] = useState('')
  const [loadingMove, setLoadingMove] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingGroup, setLoadingGroup] = useState(false)
  const [loadingBackground, setLoadingBackground] = useState(false)
  const [groupsHeadersSelected, setGroupsHeadersSelected] = useState(false)
  const [groupChildrenSelected, setGroupChildrenSelected] = useState(false)
  const [deletingGroups, setDeletingGroups] = useState(false)

  useEffect(() => {
    setGroupsHeadersSelected(selectedCues.map((cueId) => cues[cueId]?.type).find((type) => type === CUE_TYPE_GROUP) !== undefined)
    setGroupChildrenSelected(selectedCues.some((id) => cueOrder.some((cue) => cue.children?.some((child) => child.id === id))))
  }, [selectedCues, cues, cueOrder])

  function clearSelection () {
    setSelectedCues([])
  }

  function selectAllCues () {
    setSelectedCues(cueOrder.map((c) => c.id))
  }

  // this will create a group with the children in the order of selection
  async function groupCues () {
    setLoadingGroup(true)
    const firstSelected = cueOrder.find((cue) => selectedCues.includes(cue.id)).id
    try {
      await groupRundownCues(rundownId, firstSelected, selectedCues)
      clearSelection()
    } finally {
      setLoadingGroup(false)
    }
  }

  async function duplicateCues () {
    setLoadingDuplicate(true)
    try {
      await duplicateRundownCues(rundownId, selectedCues)
      clearSelection()
    } finally {
      setLoadingDuplicate(false)
    }
  }

  function onChangeMoveInput (event) {
    setMoveInput(event.target.value)
  }

  async function moveToBeginning() {
    setLoadingMove(true)
    try {
      await moveRundownCues(rundownId, selectedCues, '0')
      clearSelection()
      setMoveInput('')
    } catch (error) {
      captureException(error)
    } finally {
      setLoadingMove(false)
    }
  }

  async function moveToEnd() {
    setLoadingMove(true)
    try {
      await moveRundownCues(rundownId, selectedCues, String(Number.MAX_SAFE_INTEGER))
      clearSelection()
      setMoveInput('')
    } catch (error) {
      captureException(error)
    } finally {
      setLoadingMove(false)
    }
  }

  async function moveCues(event) {
    event?.preventDefault()
    setLoadingMove(true)
    try {
      await moveRundownCues(rundownId, selectedCues, moveInput)
      clearSelection()
      setMoveInput('')
    } catch (error) {
      captureException(error)
    } finally {
      setLoadingMove(false)
    }
  }

  async function updateBackground(backgroundColor) {
    setLoadingBackground(true)
    try {
      await updateRundownCues(rundownId, selectedCues, { backgroundColor })
      clearSelection()
    } finally {
      setLoadingBackground(false)
    }
  }

  async function deleteCues ({ confirm = false }) {
    if (!confirm && groupsHeadersSelected) return setDeletingGroups(true)
    setLoadingDelete(true)
    try {
      await deleteRundownCues(rundownId, selectedCues)
      clearSelection()
    } catch (error) {
      captureException(error)
    } finally {
      setLoadingDelete(false)
    }
  }

  return selectedCues.length > 0 && (
    <div className="z-50 fixed mx-2 p-1.5 bg-gray-900 ring-2 ring-black/70 backdrop-blur rounded w-[822px] flex text-sm font-semibold justify-between flex-wrap">
      <div className="flex gap-2">
        <Button
          type="button"
          size="sm"
          colour="dark"
          text="Select all"
          onClick={selectAllCues}
          icon={faSquareCheck}
        />
        <Button
          type="button"
          size="sm"
          colour="dark"
          text="Duplicate"
          icon={faClone}
          onClick={duplicateCues}
          loading={loadingDuplicate}
        />
        <Button
          type="button"
          size="sm"
          colour="dark"
          text="Group"
          onClick={groupCues}
          disabled={groupChildrenSelected || groupsHeadersSelected}
          loading={loadingGroup}
          showTooltipWhenDisabled={true}
          toolTip={groupChildrenSelected || groupsHeadersSelected ? 'Cannot group already grouped cues' : ''}
          icon={faLayerGroup}
        />
        <Popover>
          <PopoverTrigger asChild={true}>
            <Button
              type="button"
              size="sm"
              colour="dark"
              text="Move"
              loading={loadingMove}
              icon={faUpDown}
            />
          </PopoverTrigger>
          <PopoverContent hideButtons={true} onCancel={setMoveInput.bind(null, '')}>
            <div className="flex flex-col gap-2">
              <div>
                <p className="leading-3 text-sm text-gray-300 mb-2">Move to:</p>
                <div className="grid grid-cols-2 gap-2">
                  <Button text="Start" size="sm" className="!justify-start w-full" icon={faArrowUp} colour="dark" disabled={loadingMove} onClick={moveToBeginning} />
                  <Button text="End" size="sm" className="!justify-start w-full" icon={faArrowDown} colour="dark" disabled={loadingMove} onClick={moveToEnd} />
                </div>
              </div>
              <div>
                <label htmlFor="target-cue" className="block leading-3 text-sm text-gray-300 mb-2">Insert after:</label>
                <form className="grid grid-cols-2 gap-2" onSubmit={moveCues}>
                  <Tooltip placement="bottom">
                    <TooltipTrigger>
                      <input
                        id="target-cue"
                        type="number"
                        title=""
                        min={0}
                        step={0.01}
                        value={moveInput}
                        onChange={onChangeMoveInput}
                        className="w-20 py-0.5 bg-gray-800 hover:brightness-125 rounded text-center focus:outline-none focus:ring appearance-none [-moz-appearance:textfield]"
                        placeholder="2"
                        required
                      />
                    </TooltipTrigger>
                    <TooltipContent className="text-center">
                      Enter the number of a cue or sub-cue. Example: 2.1
                    </TooltipContent>
                  </Tooltip>
                  <Button text="Move" size="sm" className="w-full" colour="dark" disabled={loadingMove} type="submit" />
                </form>
              </div>
            </div>
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild={true}>
            <Button
              type="button"
              size="sm"
              colour="dark"
              text="Background"
              loading={loadingBackground}
              icon={faFillDrip}
            />
          </PopoverTrigger>
          <PopoverContent hideButtons={true}>
            <CueColorPicker onClick={updateBackground} disabled={loadingBackground} />
            <Button text="Edit rundown colors" size="sm" className="mx-auto mt-2" colour="dark" disabled={loadingBackground} onClick={setRundownColoursModalOpen.bind(null, true)} />
          </PopoverContent>
        </Popover>
        <Button
          type="button"
          size="sm"
          colour="dark"
          text="Delete"
          onClick={deleteCues}
          loading={loadingDelete}
          icon={faTrash}
        />
      </div>
      <div>
        <Button
          type="button"
          size="sm"
          colour="dark"
          text="Clear selection"
          onClick={clearSelection}
          icon={faRemove}
        />
      </div>
      {deletingGroups
      && (
        <ModalWrapper
          open={[deletingGroups, setDeletingGroups]}
          title="Delete Group"
          buttons={[
            {
              text: 'Cancel',
              onClick: () => setDeletingGroups(false),
            },
            {
              text: 'Delete Group',
              icon: faTrash,
              onClick: async () => {
                setDeletingGroups(false)
                deleteCues({ confirm: true })
              },
            },
          ]}
        >
          <p>Deleting this group will permanently remove all cues within it.</p>
        </ModalWrapper>
      )}
    </div>
  )
}

BatchEditToolbar.propTypes = {
  rundownId: PropTypes.string.isRequired,
  cues: PropTypes.object.isRequired,
  cueOrder: PropTypes.array.isRequired,
  setRundownColoursModalOpen: PropTypes.func,
}

export default memo(BatchEditToolbar)
