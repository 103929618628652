import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import Input from '../components/Input'
import Layout from '../components/Layout'
import { getUser, getUserTeams } from '../firebase'
import { createEvent } from '../firestore'
import Select from '../components/Select'

export default function CreateEvent () {
  const [user, setUser] = useState({})
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)

  const [eventName, setEventName] = useState('')
  const teamId = localStorage.getItem('teamId') || ''
  const [eventTeamId, setEventTeamId] = useState(teamId)

  const navigate = useNavigate()

  const handleCreateEvent = async (e) => {
    e.preventDefault()
    setLoading(true)

    const { data } = await createEvent({
      name: eventName || 'New event',
      teamId: eventTeamId,
    })

    return navigate(`/dashboard/${teamId}/event/${data?.id}`)
  }

  const checkUserAndTeam = async () => {
    const awaitedUser = await getUser()
    setUser(awaitedUser)
    const awaitedUserTeams = await getUserTeams()
    setTeams(awaitedUserTeams)
  }

  useEffect(() => {
    checkUserAndTeam()
  }, [])

  return (
    <Layout title="Create a new event">
      <form
        className="space-y-4"
        onSubmit={(e) => {
          handleCreateEvent(e)
        }}
      >
        <Input
          label="Name your event"
          placeholder="My new event"
          onChange={(change) => setEventName(change)}
          autofocus={true}
          required={true}
          value={eventName}
        />
        <Select
          label="Team"
          value={teamId}
          required={teams?.length > 0}
          disabled={!teams?.length}
          options={[
            {
              name: 'Choose a team...',
              value: '',
            },
            ...teams.map((team) => ({
              name: team.name,
              value: team.id,
            })),
          ]}
          onChange={(teamId) => {
            setEventTeamId(teamId)
          }}
        />
        <div className="flex gap-6">
          {user?.uid && (
            <Button
              text="Back"
              colour="dark"
              onClick={() => navigate('/dashboard')}
              className="w-full"
              style={{ flexGrow: 1 }}
              type="button"
              loading={loading}
            />
          )}
          <Button
            text={loading ? 'Creating...' : 'Create event'}
            className="w-full"
            style={{ flexGrow: 1 }}
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </Layout>
  )
}
