const UTM_COOKIE_NAME = 'rs:utm_params'
const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
const COOKIE_MAX_AGE = 30 * 24 * 60 * 60 // 30 days in seconds

function getUTMParams () {
  const params = new URLSearchParams(window.location.search)
  return UTM_PARAMS.reduce((acc, param) => {
    const value = params.get(param)
    if (value) acc[param] = value
    return acc
  }, {})
}

function getRootDomain () {
  const hostnameParts = window.location.hostname.split('.')
  if (hostnameParts.length > 2) {
    // For subdomains like app.rundownstudio.app
    return hostnameParts.slice(-2).join('.')
  }
  // For localhost or top-level domains
  return window.location.hostname
}

function setCookie (name, value, maxAge) {
  const domain = getRootDomain()
  const encodedValue = encodeURIComponent(JSON.stringify(value))
  document.cookie = `${name}=${encodedValue}; domain=${domain}; path=/; max-age=${maxAge}; SameSite=Lax; Secure`
}

function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const cookieValue = parts.pop().split(';').shift()
    return JSON.parse(decodeURIComponent(cookieValue))
  }
  return null
}

export function trackUTMParams () {
  const utmParams = getUTMParams()
  if (Object.keys(utmParams).length > 0) {
    setCookie(UTM_COOKIE_NAME, utmParams, COOKIE_MAX_AGE)
  }
}

export function getTrackedUTMParams () {
  return getCookie(UTM_COOKIE_NAME) || {}
}

export function clearUTMParams () {
  setCookie(UTM_COOKIE_NAME, '', 0) // Setting max-age to 0 deletes the cookie
}
