import { formatTimezone } from '@rundown-studio/timeutils'
import PropTypes from 'prop-types'
import eventBus from '../../../utils/eventBus'
import DateInput from '../DateInput'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { getAutoScrollAtom, rundownAtom, setAutoScrollAtom } from '../../../store/rundown.store'
import { updateRundown } from '../../../firestore'
import { useState } from 'react'
import AdvancedSelect from '../../interactives/AdvancedSelect'

export default function RundownDayTZ ({ timezone, readonly = false }) {
  const [rundown, setRundown] = useAtom(rundownAtom)
  const autoScrollSettings = useAtomValue(getAutoScrollAtom)
  const isAutoScrollEnabled = autoScrollSettings[rundown.id] !== false
  const setAutoScroll = useSetAtom(setAutoScrollAtom)
  const [dateLoading, setDateLoading] = useState(false)

  async function updateDate (newDate) {
    setDateLoading(true)
    const { data } = await updateRundown(rundown.id, { startTime: newDate })
    setRundown(data)
    setDateLoading(false)
  }

  function getFormattedTimezone () {
    if (!timezone) return 'Choose a timezone...'
    if (timezone === 'UTC') return formatTimezone(timezone, 'abbr')
    return `${formatTimezone(timezone, 'city')} (${formatTimezone(timezone, 'abbr')})`
  }

  return (
    <div className="flex space-x-4">
      <section>
        <div className="px-2 text-gray-500 font-semibold whitespace-pre text-[10px]">
          Rundown date
        </div>
        <span className="text-sm">
          <DateInput date={rundown.startTime} timezone={timezone} updateDate={updateDate} loading={dateLoading} disabled={readonly} />
        </span>
      </section>
      <section>
        <div className="px-2 text-gray-500 font-semibold whitespace-pre text-[10px]">
          Timezone
        </div>
        <span className="text-sm">
          <button
            className="transition-colors px-2 h-6 text-gray-300 rounded-sm enabled:hover:bg-white/20 overflow-hidden line-clamp-1 text-left"
            onClick={() => eventBus.$emit('openTimezoneModal')}
            title={timezone ? `${formatTimezone(timezone, 'long')}, ${formatTimezone(timezone, 'offset')}` : undefined}
            disabled={readonly}
          >
            {getFormattedTimezone()}
          </button>
        </span>
      </section>
      <section>
        <div className="px-2 text-gray-500 font-semibold whitespace-pre text-[10px]">
          Auto-Scroll
        </div>
        <span className="text-sm">
          <AdvancedSelect
            className="transition-colors !px-2 !h-6 text-gray-300 rounded-sm bg-transparent hover:bg-white/20 overflow-hidden !w-48 focus:outline-none focus:ring"
            options={[
              {
                value: 'true',
                label: 'Activated',
                detail: 'Automatically scrolls to the current cue',
              },
              {
                value: 'false',
                label: 'Deactivated',
                detail: 'Scroll the rundown at your own pace',
              },
            ]}
            value={String(isAutoScrollEnabled)}
            setValue={(value) => setAutoScroll(rundown.id, value === 'true')}
          />
        </span>
      </section>
    </div>
  )
}

RundownDayTZ.propTypes = {
  timezone: PropTypes.string,
  readonly: PropTypes.bool,
}
